import React from "react";

import CountryDropdown from "../CountryDropdown.tsx";
import { ResizableTextarea } from "../../ui/resizable-textarea.tsx";
import FormButton from "../FormButton.tsx";

import useStyles from "../../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

export default function Country() {
  const { getStyles } = useStyles();
  const { editorField, setEditorField, theme, setFields, device } =
    useFormEditorContext();

  const updateField = (fieldId, key, subkey, value) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === fieldId) {
          if (subkey) {
            return {
              ...field,
              [key]: {
                ...field[key],
                [subkey]: value,
              },
            };
          } else {
            return {
              ...field,
              [key]: value,
            };
          }
        }
        return field;
      });
      return updatedFields;
    });

    setEditorField((prev) => {
      if (subkey) {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [subkey]: value,
          },
        };
      } else {
        return {
          ...prev,
          [key]: value,
        };
      }
    });
  };

  return (
    <div className="flex flex-col gap-0 w-full max-w-[606px]">
      <ResizableTextarea
        placeholder="Add question title"
        value={editorField?.title || ""}
        onChange={(value) => updateField(editorField?.id, "title", null, value)}
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h2", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h2", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
      />
      <ResizableTextarea
        placeholder="Add question description (Optional)"
        value={editorField?.properties?.description || ""}
        onChange={(value) =>
          updateField(editorField?.id, "properties", "description", value)
        }
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h4", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h4", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
        className="mt-2"
      />
      <CountryDropdown />
      <FormButton className="mt-3">Next</FormButton>
    </div>
  );
}
