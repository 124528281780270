export type Pagination = {
  page: number | string | null | undefined;
  size: number | string | null | undefined;
};

export const DEFAULT_PAGINATION: Pagination = {
  page: 1,
  size: 20,
};

function usePagination() {
  const getPagination = (pagination?: Pagination) => {
    let paginationStr = "";
    try {
      if (pagination?.size) {
        paginationStr += `&size=${pagination.size}`;
      }

      if (pagination?.page) {
        paginationStr += `&page=${pagination.page}`;
      }

      return paginationStr
        ? `${paginationStr.slice(1)}`
        : `page=${DEFAULT_PAGINATION.page}&size=${DEFAULT_PAGINATION.size}`;
    } catch (e) {
      console.log(e);

      return `page=${DEFAULT_PAGINATION.page}&size=${DEFAULT_PAGINATION.size}`;
    }
  };

  return { getPagination };
}

export default usePagination;
