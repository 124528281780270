import axios, { Axios, AxiosResponse } from "axios";
import { ENDPOINTS } from "../Constants/api.ts";
import { useAuth } from "@clerk/clerk-react";
import useFilters, { Filtering } from "./useFilters.ts";
import usePagination, { Pagination } from "./usePagination.ts";
interface startResponseData {
  form_id: string;
  signature: string;
  frontend_version: string;
  landed_at: Date;
  updated_at: Date;
  type: string;
  visit_response_id: string;
}

export const useApi = (): {
  forms: {
    fetchFormData: (id: string) => Promise<AxiosResponse<any, any>>;
    fetchFormList: (workspace_id: string) => Promise<AxiosResponse<any, any>>;
    createForm: (form: any) => Promise<AxiosResponse<any, any>>;
    deleteForm: (id: string) => Promise<AxiosResponse<any, any>>;
    updateForm: (id: string, form: any) => Promise<AxiosResponse<any, any>>;
    fetchFormResponses: (
      id: string,
      pagination?: Pagination,
      filtering?: Filtering
    ) => Promise<AxiosResponse<any, any>>;
    deleteFormResponse: (id: string) => Promise<AxiosResponse<any, any>>;
    exportResponses: (id: string) => Promise<AxiosResponse<any, any>>;
  };
  aiForms: {
    createAiForms: (form: any) => Promise<AxiosResponse<any, any>>;
  };
  responses: {
    startResponse: (
      data: startResponseData
    ) => Promise<AxiosResponse<any, any>>;
    updateResponse: (
      responseId: string,
      answers: any[]
    ) => Promise<AxiosResponse<any, any>>;
    getResponses: (form_id: string) => Promise<AxiosResponse<any, any>>;
  };
  files: {
    uploadFile: (file: any, theme_id: any) => Promise<AxiosResponse<any, any>>;
    getFiles: (file_id: string) => Promise<AxiosResponse<any, any>>;
  };
  workspaces: {
    createWorkspace: (data: any) => Promise<AxiosResponse<any, any>>;
    getWorkspaces: () => Promise<AxiosResponse<any, any>>;
    updateWorkspace: (
      workspace_id: string,
      data: any
    ) => Promise<AxiosResponse<any, any>>;
    deleteWorkspace: (workspace_id: string) => Promise<AxiosResponse<any, any>>;
  };
  utils: {
    getCountries: () => Promise<AxiosResponse<any, any>>;
  };

  themes: {
    createTheme: (data: any) => Promise<AxiosResponse<any, any>>;
    updateTheme: (
      theme_id: string,
      data: any
    ) => Promise<AxiosResponse<any, any>>;
    deleteTheme: (theme_id: string) => Promise<AxiosResponse<any, any>>;
  };
  dashboard: {
    getStats: (
      workspace_id: string,
      time_interval: string
    ) => Promise<AxiosResponse<any, any>>;
  };
} => {
  const { getToken } = useAuth();

  return {
    forms: {
      fetchFormData: async (id: string): Promise<AxiosResponse<any, any>> => {
        return axios.get(ENDPOINTS.forms.getOne(id)).catch((err) => {
          console.error(err);
          return err;
        });
      },

      fetchFormList: async (
        workspace_id: string
      ): Promise<AxiosResponse<any, any>> => {
        try {
          const token = await getToken();
          const response = await axios.get(
            ENDPOINTS.forms.getList(workspace_id),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          return response;
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      createForm: async (form: any): Promise<AxiosResponse<any, any>> => {
        try {
          const token = await getToken();
          const response = await axios.post(ENDPOINTS.forms.createOne, form, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response;
        } catch (error) {
          console.error("Error creating form:", error);
          throw error;
        }
      },
      deleteForm: async (id: string): Promise<AxiosResponse<any, any>> => {
        const token = await getToken();
        return axios
          .delete(ENDPOINTS.forms.deleteOne(id), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      },
      updateForm: async (
        id: string,
        form: any
      ): Promise<AxiosResponse<any, any>> => {
        try {
          const token = await getToken();
          const response = await axios.put(
            ENDPOINTS.forms.updateOne(id),
            form,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          return response;
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      fetchFormResponses: async (
        id: string,
        pagination?: Pagination,
        filtering?: Filtering
      ): Promise<AxiosResponse<any, any>> => {
        try {
          const token = await getToken();
          const { getFilters } = useFilters();
          const filters = getFilters(filtering);
          const { getPagination } = usePagination();
          const _pagination = getPagination(pagination);

          const queryParams = `${filters}&${_pagination}`;

          const response = axios.get(
            ENDPOINTS.forms.getFormResponses(id, queryParams),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          return response;
        } catch (err) {
          console.error(err);
          return err;
        }
      },
      deleteFormResponse: async (
        id: string
      ): Promise<AxiosResponse<any, any>> => {
        try {
          const token = await getToken();
          const response = axios.delete(
            ENDPOINTS.forms.deleteFormResponse(id),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          return response;
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      exportResponses: async (id: string): Promise<AxiosResponse<any, any>> => {
        try {
          const token = await getToken();
          const response = axios.get(ENDPOINTS.forms.exportFormResponses(id), {
            headers: {
              Authorization: `Bearer ${token}`,
              responseType: "blob",
            },
          });
          return response;
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
    },
    aiForms: {
      createAiForms: async (form: any): Promise<AxiosResponse<any, any>> => {
        try {
          const token = await getToken();
          const response = await axios.post(ENDPOINTS.aiForms.createOne, form, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response;
        } catch (error) {
          console.error("Error creating Ai-form:", error);
          throw error;
        }
      },
    },
    responses: {
      startResponse: async (
        data: startResponseData
      ): Promise<AxiosResponse<any, any>> => {
        return axios.post(ENDPOINTS.responses.createOne, data).catch((err) => {
          console.error(err);
          return err;
        });
      },

      updateResponse: async (
        responseId: string,
        answers: any[]
      ): Promise<AxiosResponse<any, any>> => {
        return axios
          .put(ENDPOINTS.responses.updateOne(responseId), answers)
          .catch((err) => {
            console.error(err);
            return err;
          });
      },

      getResponses: async (
        form_id: string
      ): Promise<AxiosResponse<any, any>> => {
        return axios
          .get(ENDPOINTS.responses.getResponses(form_id))
          .catch((err) => {
            console.error(err);
            return err;
          });
      },
    },

    files: {
      uploadFile: async (
        file: any,
        theme_id: any
      ): Promise<AxiosResponse<any, any>> => {
        try {
          const token = await getToken();
          const response = axios.post(ENDPOINTS.files.upload(theme_id), file, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response;
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      getFiles: async (file_id: string): Promise<AxiosResponse<any, any>> => {
        return axios.get(ENDPOINTS.files.getFiles(file_id)).catch((err) => {
          console.error(err);
          return err;
        });
      },
    },

    workspaces: {
      createWorkspace: async (data: any): Promise<AxiosResponse<any, any>> => {
        const token = await getToken();
        try {
          const response = axios.post(ENDPOINTS.workspace.createOne, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      getWorkspaces: async (): Promise<AxiosResponse<any, any>> => {
        const token = await getToken();
        return axios
          .get(ENDPOINTS.workspace.getList, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      },

      updateWorkspace: async (
        workspace_id: string,
        data: any
      ): Promise<AxiosResponse<any, any>> => {
        const token = await getToken();
        try {
          const response = axios.put(
            ENDPOINTS.workspace.updateOne(workspace_id),
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },

      deleteWorkspace: async (
        workspace_id: string
      ): Promise<AxiosResponse<any, any>> => {
        const token = await getToken();
        return axios
          .delete(ENDPOINTS.workspace.updateOne(workspace_id), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      },
    },

    utils: {
      getCountries: async (): Promise<AxiosResponse<any, any>> => {
        try {
          const response = await axios.get(ENDPOINTS.utils.getCountries);
          return response;
        } catch (err) {
          console.error(err);
          return err;
        }
      },
    },
    themes: {
      createTheme: async (theme: any): Promise<AxiosResponse<any, any>> => {
        const token = await getToken();
        try {
          const response = await axios.post(ENDPOINTS.theme.createOne, theme, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      updateTheme: async (
        theme_id: string,
        theme: any
      ): Promise<AxiosResponse<any, any>> => {
        const token = await getToken();

        try {
          const response = axios.put(
            ENDPOINTS.theme.updateAtheme(theme_id),
            theme,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      deleteTheme: async (
        theme_id: string
      ): Promise<AxiosResponse<any, any>> => {
        const token = await getToken();
        return axios
          .delete(ENDPOINTS.theme.deleteAtheme(theme_id), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      },
    },
    dashboard: {
      getStats: async (
        workspace_id: string,
        time_interval: string
      ): Promise<AxiosResponse<any, any>> => {
        const token = await getToken();

        return axios
          .get(ENDPOINTS.Dashboard.getStats(workspace_id, time_interval), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .catch((err) => {
            console.error(err);
            return err;
          });
      },
    },
  };
};

/* Dashboard.getStats(workspace_id, time_interval) */
