import React from "react";
import cover from "../../assets/images/cover.svg";
import { motion } from "framer-motion";

const CommonLayout = ({ children }) => {
  return (
    <div className="grid w-full h-screen grid-cols-2 ">
      {/* <div
        style={{
          backgroundImage: `url(${cover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="w-full h-full"
      /> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="w-full h-full overflow-hidden"
      >
        <img src={cover} alt="Cover" className="object-cover w-full h-full" />
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="grid flex-col items-center justify-center w-full grid-cols-4 mx-auto bg-white "
      >
        <div className="col-span-1 "></div>
        <div className="col-span-2 max-w-[400px] w-full mx-auto ">
          {children}
        </div>
        <div className="col-span-1 "></div>
      </motion.div>
    </div>
  );
};

export default CommonLayout;
