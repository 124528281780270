import React, { useState } from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { message } from "antd";
import { Separator } from "../ui/separator.tsx";
import { Button } from "../ui/button.tsx";
import { motion } from "framer-motion";
import { sideFade } from "../../Constants/animations";

const ShareTab = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { form } = useFormEditorContext();

  const publicUrl = process.env.REACT_APP_PUBLISHED_URL + "forms/";

  const urlEndPoint = `${publicUrl}${form?.url_id}`;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(urlEndPoint).then(
      () => {
        //messageApi.success("Link copied to clipboard");
        messageApi.open({
          type: "success",
          content: "Link copied to clipboard",
          className: "custom-class",
          style: {
            marginLeft: "40vh",
          },
        });
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const [copySuccess, setCopySuccess] = useState("");

  const iframeCode = `<iframe src=${publicUrl}${form?.url_id}
    title="Embedded Form"
    width="100%"
    height="600px"
    style="border: none;">
</iframe>`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(iframeCode).then(
      () => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 2000); // Clear the success message after 2 seconds
      },
      () => {
        setCopySuccess("Failed to copy!");
      }
    );
  };

  return (
    <>
      {contextHolder}
      <div className="pb-4 mt-6 ">
        <div>
          <div className="flex items-center gap-1 mb-1">
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_436_3493)">
                <path
                  d="M5.83321 8.43357C6.08372 8.76848 6.40333 9.0456 6.77036 9.24612C7.13739 9.44665 7.54325 9.56589 7.96042 9.59577C8.37758 9.62565 8.7963 9.56545 9.18816 9.41928C9.58002 9.2731 9.93586 9.04436 10.2315 8.74857L11.9815 6.99857C12.5128 6.44848 12.8068 5.71173 12.8002 4.94699C12.7935 4.18225 12.4868 3.45071 11.946 2.90994C11.4052 2.36916 10.6737 2.06242 9.90896 2.05577C9.14422 2.04913 8.40746 2.34311 7.85737 2.87441L6.85404 3.87191M8.16654 7.26691C7.91602 6.932 7.59641 6.65488 7.22938 6.45436C6.86236 6.25383 6.45649 6.13459 6.03933 6.10471C5.62216 6.07484 5.20345 6.13503 4.81159 6.2812C4.41973 6.42738 4.06389 6.65612 3.76821 6.95191L2.01821 8.70191C1.48691 9.252 1.19293 9.98875 1.19957 10.7535C1.20622 11.5182 1.51296 12.2498 2.05373 12.7905C2.59451 13.3313 3.32604 13.6381 4.09079 13.6447C4.85553 13.6514 5.59228 13.3574 6.14237 12.8261L7.13987 11.8286"
                  stroke="#191B33"
                  stroke-width="0.875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_436_3493">
                  <rect
                    width="14"
                    height="14"
                    fill="white"
                    transform="translate(0 0.849609)"
                  />
                </clipPath>
              </defs>
            </svg>

            <h4 className="text-sm font-medium text-[#191B33]">Link</h4>
          </div>
          <p className="text-xs flex justify-start w-full text start text-[#44546F]">
            The public link of this form
          </p>

          <div className="p-1 mt-3 text-left border rounded-[8px]  border-[#DCDFE4]">
            <p className="px-3 py-3 pt-2 overflow-x-auto overflow-y-hidden text-xs no-scrollbar">
              {urlEndPoint}
            </p>
            <Button
              className="inline-flex items-center justify-center w-full h-9 px-3 text-xs  transition rounded-lg gap-2 whitespace-nowrap font-medium ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[#191B33] text-zinc-50 "
              onClick={handleCopyClick}
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.83317 9.68229C2.1915 9.68229 1.6665 9.15729 1.6665 8.51562V2.68229C1.6665 2.04062 2.1915 1.51562 2.83317 1.51562H8.6665C9.30817 1.51562 9.83317 2.04062 9.83317 2.68229M6.33317 5.01562H12.1665C12.8108 5.01562 13.3332 5.53796 13.3332 6.18229V12.0156C13.3332 12.66 12.8108 13.1823 12.1665 13.1823H6.33317C5.68884 13.1823 5.1665 12.66 5.1665 12.0156V6.18229C5.1665 5.53796 5.68884 5.01562 6.33317 5.01562Z"
                  stroke="white"
                  stroke-width="0.875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Copy Link</span>
            </Button>
          </div>
        </div>
        <Separator className="my-4 " />
        <div>
          <div className="flex items-center gap-1 mb-1">
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4998 10.1836L12.8332 7.85026L10.4998 5.51693M3.49984 5.51693L1.1665 7.85026L3.49984 10.1836M8.45817 3.18359L5.5415 12.5169"
                stroke="#191B33"
                stroke-width="0.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <h4 className="text-sm font-medium text-[#191B33]">Embed</h4>
          </div>
          <p className="text-xs flex justify-start w-full text start text-[#44546F]">
            The public link of this form
          </p>
          <div
            style={{
              fontSize: "",
            }}
            className=" p-1 pt-2 mt-2 border border-[#DCDFE4] rounded-[8px] overflow-hidden  text-[0.75rem]"
          >
            <code
              style={{
                display: "block",
                whiteSpace: "pre-wrap",
                backgroundColor: "transparent",
                color: "#383A42",
                fontFamily: "inter, sans-serif",
                textAlign: "left",
              }}
              className="overflow-x-auto break-words language-html no-scrollbar"
            >
              {iframeCode}
            </code>
            <Button
              className=" h-9 rounded-[8px] text-xs w-full bg-[#191B33]"
              onClick={copyToClipboard}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                style={{
                  height: "0.75rem",
                  width: "0.75rem",
                  marginRight: "0.5rem",
                }}
              >
                <path d="M7 3.5A1.5 1.5 0 0 1 8.5 2h3.879a1.5 1.5 0 0 1 1.06.44l3.122 3.12A1.5 1.5 0 0 1 17 6.622V12.5a1.5 1.5 0 0 1-1.5 1.5h-1v-3.379a3 3 0 0 0-.879-2.121L10.5 5.379A3 3 0 0 0 8.379 4.5H7v-1Z"></path>
                <path d="M4.5 6A1.5 1.5 0 0 0 3 7.5v9A1.5 1.5 0 0 0 4.5 18h7a1.5 1.5 0 0 0 1.5-1.5v-5.879a1.5 1.5 0 0 0-.44-1.06L9.44 6.439A1.5 1.5 0 0 0 8.378 6H4.5Z"></path>
              </svg>
              Copy Full Page Embed Code
            </Button>
            {copySuccess && (
              <div style={{ color: "#38a169", marginTop: "0.5rem" }}>
                {copySuccess}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareTab;
