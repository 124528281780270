import * as React from "react";

import { cn } from "../../lib/utils";

type ResizableTextareaProps = React.HTMLAttributes<HTMLInputElement> & {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
};

export function ResizableTextarea({
  className,
  style,
  value,
  placeholder,
  onChange,
  onKeyUp,
  ...props
}: ResizableTextareaProps) {
  const ref = React.useRef<HTMLTextAreaElement>(null);

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    // 13: Enter, 46: Delete, 8: Backspace
    if (e.keyCode === 46 || e.keyCode === 13 || e.keyCode === 8) {
      adjustHeight();
    }
    onKeyUp && onKeyUp(e);
  };

  const adjustHeight = () => {
    if (!ref || !ref.current) return;
    ref.current.style.height = "inherit";

    const newHeight = ref.current.scrollHeight;
    ref.current.style.height = `${newHeight}px`;
  };

  return (
    <textarea
      ref={ref}
      className={cn(
        "p-0 m-0 h-fit text-2xl text-left font-bold bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 border-none outline-none overflow-hidden max-h-72",
        className
      )}
      rows={1}
      placeholder={placeholder}
      style={{ ...style, resize: "none" }}
      value={value}
      onChange={handleOnChange}
      onKeyUp={handleOnKeyUp}
    />
  );
}
