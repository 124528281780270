import React, { useState, useEffect } from "react";
import { Typography, Flex } from "antd";
//import { Button } from "../ui/button.tsx";
import { Button } from "antd";
import { motion } from "framer-motion";
import { EnterOutlined } from "@ant-design/icons";
import { useFormContext } from "../../Contexts/FormContext";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";

export const FormNextButton = ({
  index,
  // onClick = () => {},
  children,
  isEndScreen = false,
  ...rest
}) => {
  const {
    nextButtonPress,
    answers,
    questions,
    currentQuestionIndex,
    formTheme,
  } = useFormContext();

  const { theme } = useFormEditorContext();
  const [buttonRadius, setButtonRadius] = useState(0);

  const isFormEditor = location.pathname.includes("form-editor");
  const constTheme = isFormEditor ? theme : formTheme;

  let button_size;
  switch (constTheme?.button_size) {
    case "small":
      button_size = "sm";
      break;
    case "large":
      button_size = "default";
      break;
    default:
      button_size = "lg";
      break;
  }

  const [isAnimating, setIsAnimating] = useState(true);

  // const onClick = () => {};

  //console.log("isMultiple ", answers, isMultiple);
  useEffect(() => {
    let timeout;

    const checkAnimationCondition = () => {
      const checkQuestion = questions[currentQuestionIndex];
      const question_id = checkQuestion?.id;
      const isMultiple = checkQuestion?.properties?.allow_multiple_selection;

      if (isMultiple && answers[question_id]?.length >= 1) {
        setIsAnimating(true);

        timeout = setTimeout(() => {
          setIsAnimating(false);
        }, 1000);
      } else {
        setIsAnimating(false); // Stop animation if the condition is not met
      }
    };

    checkAnimationCondition();

    return () => clearTimeout(timeout); // Clear the timeout on cleanup
  }, [questions, currentQuestionIndex, answers]);

  useEffect(() => {
    setButtonRadius(constTheme?.rounded_corners);
  }, [constTheme?.rounded_corners, buttonRadius]);

  return (
    <motion.div
      key={`next ${index}`}
      /*    initial={{ opacity: 0, translateY: 400 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: 40 }}
      transition={{ delay: 0, duration: 0.3 }} */
      initial={{ opacity: 0, translateY: 400 }}
      animate={{ opacity: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: 40 }}
      transition={{ delay: 0, duration: 0.1 }}
    >
      <Flex style={{ alignItems: "center" }} gap="large">
        <Button
          size={constTheme?.button_size}
          /* onClick={() => {
            nextButtonPress();
          }} */
          onClick={nextButtonPress}
          className="px-4 py-2 "
          style={{
            backgroundColor: constTheme?.button_color,
            color: constTheme?.button_text_color,
            // color: "white",
            // padding: "10px",

            borderRadius: `${buttonRadius}px`,
            // border: "none",
            // cursor: "pointer",
            // width: "100px",

            fontWeight:
              constTheme?.is_bold === "true"
                ? "bold"
                : constTheme?.is_bold === "false"
                ? "normal"
                : "bold",
          }}
          // type="primary"
          {...rest}
          // icon={<CheckOutlined color="black"/>}
        >
          {children}
        </Button>

        {!isEndScreen && (
          <Flex gap="small">
            <Typography.Text
              style={{
                fontSize: 12,
                fontFamily: `${constTheme?.font_family?.family}, ${constTheme?.font_family?.category}`,
              }}
            >
              {"press "}
              <Typography.Text
                style={{
                  fontSize: 12,
                  fontFamily: `${constTheme?.font_family?.family}, ${constTheme?.font_family?.category}`,
                }}
                strong
              >
                Enter
              </Typography.Text>
            </Typography.Text>
            <EnterOutlined />
          </Flex>
        )}
      </Flex>
    </motion.div>
  );
};
