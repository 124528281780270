export type Filtering = {
  query?: string;
  date?: { from?: string; to?: string };
};

function useFilters() {
  const getFilters = (filtering?: Filtering) => {
    let filterStr = "";
    if (filtering?.query) {
      filterStr += `&query=${filtering.query}`;
    }

    if (filtering?.date?.from) {
      filterStr += `&since=${filtering.date.from}`;
    }

    if (filtering?.date?.to) {
      filterStr += `&until=${filtering.date.to}`;
    }

    return filterStr ? `${filterStr.slice(1)}` : "";
  };

  return { getFilters };
}

export default useFilters;
