import React from "react";

import { motion } from "framer-motion";

import Email from "./Email.tsx";
import Legal from "./Legal.tsx";
import Country from "./Country.tsx";
import Dropdown from "./Dropdown.tsx";
import Statement from "./Statement.tsx";
import ShortText from "./ShortText.tsx";
import ContactInfo from "./ContactInfo.tsx";
import PhoneNumber from "./PhoneNumber.tsx";
import MultipleChoice from "./MultipleChoice.tsx";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import { QUESTION_TYPES } from "../../../Constants/questionTypes.js";
import { questionAnimation } from "../../../Constants/animations";

import { cn } from "../../../lib/utils.js";

function QuestionScreen() {
  const { selectedField, editorField, device } = useFormEditorContext();

  const type = selectedField?.type;

  const attachment = editorField?.attachment;
  const hasAttachment = !!attachment?.link;
  const attachmentPlacement =
    (hasAttachment && attachment?.placement) || "right";

  const getParentStyle = () => {
    if (attachment?.href || attachment?.link) {
      return `flex ${
        attachmentPlacement === "right" ? "flex-row" : "flex-row-reverse"
      } items-center gap-16`;
    }
    return `w-full flex flex-col items-center justify-center`;
  };

  const parentStyle = getParentStyle();

  let Component: any;

  if (type === QUESTION_TYPES.SHORT_TEXT) Component = <ShortText />;

  if (type === QUESTION_TYPES.EMAIL) Component = <Email />;

  if (type === QUESTION_TYPES.MULTIPLE_CHOICE) Component = <MultipleChoice />;

  if (type === QUESTION_TYPES.CONTACT_INFORMATION) Component = <ContactInfo />;

  if (type === QUESTION_TYPES.PHONE_NUMBER) Component = <PhoneNumber />;

  if (type === QUESTION_TYPES.DROPDOWN) Component = <Dropdown />;

  if (type === QUESTION_TYPES.COUNTRY) Component = <Country />;

  if (type === QUESTION_TYPES.LEGAL) Component = <Legal />;

  if (type === QUESTION_TYPES.SECTION) Component = <Statement />; // NOTE: Changed to QUESTION_TYPES.SECTION until the statement type is implemented in the backend.

  return (
    <div
      className={cn(
        parentStyle,
        "h-full overflow-y-auto",
        device === "mobile" && "p-5"
      )}
    >
      {Component}
      {hasAttachment && (
        <motion.img
          {...questionAnimation}
          src={attachment?.href || attachment?.link}
          style={{
            // ...attachmentSize,
            objectFit: "contain",
            borderRadius: "18px",
          }}
          className="max-w-2xl"
        />
      )}
    </div>
  );
}

export default QuestionScreen;
