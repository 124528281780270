"use strict";

import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import WelcomeScreenSettings from "../MyEditor/WelcomeScreenSettings.jsx";
import { FormHeaderFormat } from "../../Components/Form/FormHeader";
import logoWithText from "../../assets/images/logoWithText.png";
import { Button } from "../ui/button.tsx";
import { FIELD_TYPE_SETTINGS } from "../../Constants/questionTypes.js";
import { FieldSettingEditor } from "../FormEditor/Fields/FieldSetting";

import QuestionScreen from "../FormEditor/Questions/QuestionScreen.tsx";
import WelcomeScreen from "../FormEditor/WelcomeScreen.tsx";
import EndScreen from "../FormEditor/EndScreen.tsx";

import { cn } from "../../lib/utils.js";

const questionAnimation = {
  initial: {
    opacity: 0,
    y: 400,
    x: 0, // Ensure horizontal position stays centered
    scale: 0.95, // Slight scale down for a smoother entry
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    scale: 1,
  },
  exit: {
    opacity: 0,
    y: 40,
    x: 0,
    scale: 0.95,
  },
  transition: {
    type: "tween", // Use tween for more precise control
    duration: 0.5,
    ease: "easeOut",
  },
};

const ContentMainSection = () => {
  const { selectedField, fields, theme, device } = useFormEditorContext();
  const index = fields?.findIndex((field) => field?.id === selectedField?.id);

  const deviceStyles =
    device === "mobile" ? "w-[362px] h-[672px] rounded-[8px]" : "w-full h-full";

  return (
    <div className="relative flex flex-col w-full h-full overflow-hidden">
      {/* Header Section */}
      <div className="absolute z-10 w-full px-[3.5px] top-1">
        {selectedField !== "welcome-screen" &&
          selectedField !== "end-screen" &&
          selectedField && (
            <FormHeaderFormat
              showPercentage={selectedField !== "welcome-screen"}
              percentage={((index + 1) / fields.length) * 100}
              strokeColor={theme?.button_color || "rgb(208, 252, 3)"}
            />
          )}
      </div>
      {/* Question Settings */}
      <div className="relative z-20 w-full pr-4 justify-items-end">
        {selectedField === "welcome-screen" ? (
          <WelcomeScreenSettings />
        ) : (
          <QuestionSettings />
        )}
      </div>
      {/* Main Content Section */}
      <div
        className={`relative flex flex-grow w-full items-center justify-center overflow-hidden`}
      >
        {/* Change to overflow-hidden */}
        <AnimatePresence mode="wait">
          <div
            className={cn(
              `relative flex items-center justify-center`,
              deviceStyles
            )}
            style={{
              backgroundColor: theme?.background_color,
            }}
          >
            {" "}
            {/* Add h-full */}
            {selectedField === "welcome-screen" ? (
              <motion.div
                key="welcome-screen"
                className="absolute flex items-center bottom-[5%] justify-center w-full h-full"
                {...questionAnimation}
              >
                {/* <WelcomeScreenEditor /> */}
                <WelcomeScreen />
              </motion.div>
            ) : selectedField === "end-screen" ? (
              <motion.div
                key="end-screen"
                className="absolute w-full flex justify-center items-center bottom-[45%]"
                {...questionAnimation}
              >
                {/* <EndScreenEditor /> */}
                <EndScreen />
              </motion.div>
            ) : (
              <motion.div
                key={selectedField?.id}
                className="absolute flex items-center justify-center w-full h-full"
                {...questionAnimation}
              >
                <QuestionScreen />
                {/* <QuestionEditor selectedField={selectedField} /> */}
              </motion.div>
            )}
          </div>
        </AnimatePresence>
      </div>
      {/* Footer Section */}
      <div className="absolute left-0 right-0 z-30 bottom-1">
        {selectedField !== "end-screen" && selectedField && (
          <EditorFooter index={index} />
        )}
      </div>
    </div>
  );
};

export default ContentMainSection;

const EditorFooter = ({ index }) => {
  const { fields, setSelectedField } = useFormEditorContext();
  const isLastField = index === fields?.length - 1;

  const nextField = fields[index + 1];
  const previousField = fields[index - 1];

  const handleFieldChange = (field) => {
    setSelectedField(field);
  };

  const nextButtonPress = () => {
    handleFieldChange(isLastField ? "end-screen" : nextField);
  };

  const previous = () => {
    handleFieldChange(index === 0 ? "welcome-screen" : previousField);
  };

  return (
    <div className="relative w-full py-4 ">
      <div className="absolute bottom-2 left-[17px]">
        <img src={logoWithText} className="h-6 w-[108.75px]" alt="Logo" />
      </div>
      <div className="absolute flex gap-1 bottom-2 right-4 ">
        <Button
          icon="left"
          variant="ghost"
          className="bg-[#F1F2F4] w-9 h-9 p-0"
          onClick={previous}
        >
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 12.3496L6 8.34961L10 4.34961"
              stroke="#191B33"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <Button
          icon="right"
          variant="ghost"
          className="bg-[#F1F2F4] w-9 h-9 p-0"
          onClick={nextButtonPress}
        >
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 12.3496L10 8.34961L6 4.34961"
              stroke="#191B33"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

const QuestionSettings = () => {
  const { selectedField, setEditorField, editorField } = useFormEditorContext();
  const fieldTypeSettings = FIELD_TYPE_SETTINGS[selectedField?.type];

  const renderSettings = (settings, parentKey = "") => {
    if (!settings) return null;

    return Object.keys(settings).map((key) => {
      const value = settings[key];
      const fullKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === "object" && !Array.isArray(value)) {
        return <div key={fullKey}>{renderSettings(value, fullKey)}</div>;
      }

      return (
        <FieldSettingEditor
          key={fullKey}
          type={value}
          label={fullKey}
          setEditorField={setEditorField}
          editorField={editorField}
        />
      );
    });
  };

  return (
    <AnimatePresence>
      {selectedField && (
        <motion.div
          key="question-settings"
          className="absolute flex gap-1 right-4 top-7"
          {...questionAnimation}
        >
          {renderSettings(fieldTypeSettings)}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
