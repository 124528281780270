export const sideFade = {
  initial: { opacity: 0, x: -100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -100 },
  transition: { type: "tween", duration: 0.25 },
};

export const sideFadeRight = {
  initial: { opacity: 0, x: 100 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 100 },
  transition: { type: "tween", duration: 0.25 },
};

export const fadeAnimation = {
  /*  initial: { opacity: 0, translateY: 30 },
  animate: { opacity: 1, translateY: 0 },
  exit: { opacity: 0, translateY: -30 },
  transition: { type: "tween", duration: 0.5 }, */
  initial: { opacity: 0, translateY: 400 },
  animate: { opacity: 1, translateY: 0 },
  exit: { opacity: 0, translateY: 40 },
  transition: { delay: 0, duration: 0.5 },
};

export const elementAnimation = {
  /* initial: { opacity: 0, translateY: 10 },
  animate: { opacity: 1, translateY: 0 },
  exit: { opacity: 0, translateY: 10 }, */
  initial: { opacity: 0, translateY: 400 },
  animate: { opacity: 1, translateY: 0 },
  exit: { opacity: 0, translateY: 40 },
  transition: { delay: 0, duration: 0.5 },
};

export const QUESTION_INDEX_ANIMATION_DURATION = 0.5;
export const TEXT_FADE_STAGGER_DURATION = 0.08;
export const INPUT_ANIMATION_DURATION = 0.5;

export const questionAnimation = {
  /*  initial: { opacity: 0, translateY: 30 },
  animate: { opacity: 1, translateY: 0 },
  exit: { opacity: 0, translateY: -30 },
  style: { alignContent: "center", justifyContent: "center", display: "flex" },
  transition: { type: "tween", duration: 0.15 }, */
  initial: { opacity: 0, translateY: 400 },
  animate: { opacity: 1, translateY: 0 },
  exit: { opacity: 0, translateY: 40 },
  transition: { delay: 0, duration: 0.5 },
  style: { alignContent: "center", justifyContent: "center", display: "flex" },
};

export const questionIndexAnimation = {
  initial: { opacity: 0, translateX: -40 },
  animate: { opacity: 1, translateX: 0 },
  exit: { opacity: 0, translateX: -40 },
  transition: { duration: QUESTION_INDEX_ANIMATION_DURATION },
};
