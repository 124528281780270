import {
  CheckSquareOutlined,
  FileTextOutlined,
  GlobalOutlined,
  CreditCardOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

export const QUESTION_ICONS = {
  multiple_choice: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.3335 3.5H12.7502M6.3335 7H12.7502M6.3335 10.5H12.7502M2.8335 3.5H3.41683V5.83333M2.8335 5.83333H4.00016M4.00016 10.5H2.8335C2.8335 9.91667 4.00016 9.33333 4.00016 8.75C4.00016 8.16667 3.41683 7.875 2.8335 8.16667"
        stroke="#027A48"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  short_text: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00016 11.2739H11.8402M9.42016 2.40054C9.6341 2.1866 9.92426 2.06641 10.2268 2.06641C10.3766 2.06641 10.525 2.09591 10.6634 2.15324C10.8018 2.21057 10.9276 2.29461 11.0335 2.40054C11.1394 2.50647 11.2235 2.63223 11.2808 2.77064C11.3381 2.90905 11.3676 3.05739 11.3676 3.20721C11.3676 3.35702 11.3381 3.50536 11.2808 3.64377C11.2235 3.78218 11.1394 3.90794 11.0335 4.01387L4.31127 10.7361L2.16016 11.2739L2.69793 9.12276L9.42016 2.40054Z"
        stroke="#B54708"
        stroke-width="0.88"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  email: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8332 4.08203L7.60067 7.40703C7.42058 7.51986 7.21236 7.5797 6.99984 7.5797C6.78732 7.5797 6.5791 7.51986 6.399 7.40703L1.1665 4.08203M2.33317 2.33203H11.6665C12.3108 2.33203 12.8332 2.85437 12.8332 3.4987V10.4987C12.8332 11.143 12.3108 11.6654 11.6665 11.6654H2.33317C1.68884 11.6654 1.1665 11.143 1.1665 10.4987V3.4987C1.1665 2.85437 1.68884 2.33203 2.33317 2.33203Z"
        stroke="#6A32EE"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  dropdown: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 5.25L7 8.75L10.5 5.25"
        stroke="#027A48"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  phone_number: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8716 9.38915V10.849C11.8721 10.9845 11.8444 11.1186 11.7901 11.2428C11.7358 11.367 11.6562 11.4784 11.5563 11.5701C11.4564 11.6617 11.3385 11.7314 11.2101 11.7748C11.0818 11.8183 10.9457 11.8344 10.8108 11.8222C9.31339 11.6595 7.87506 11.1478 6.61134 10.3283C5.43561 9.5812 4.43879 8.58438 3.69169 7.40866C2.86931 6.13919 2.35752 4.69386 2.1978 3.18976C2.18564 3.0552 2.20163 2.91958 2.24476 2.79153C2.28788 2.66349 2.3572 2.54583 2.44829 2.44604C2.53938 2.34625 2.65025 2.26652 2.77384 2.21193C2.89743 2.15734 3.03104 2.12908 3.16615 2.12895H4.62597C4.86213 2.12663 5.09107 2.21026 5.27013 2.36424C5.44918 2.51823 5.56614 2.73208 5.59919 2.96592C5.66081 3.4331 5.77507 3.8918 5.93982 4.33329C6.00529 4.50746 6.01946 4.69674 5.98065 4.87872C5.94184 5.06069 5.85167 5.22773 5.72084 5.36003L5.10285 5.97803C5.79556 7.19627 6.80425 8.20496 8.0225 8.89768L8.64049 8.27968C8.7728 8.14885 8.93983 8.05869 9.12181 8.01988C9.30378 7.98107 9.49307 7.99524 9.66724 8.06071C10.1087 8.22545 10.5674 8.33972 11.0346 8.40134C11.271 8.43468 11.4869 8.55374 11.6412 8.73588C11.7955 8.91801 11.8775 9.15051 11.8716 9.38915Z"
        stroke="#6A32EE"
        stroke-width="0.88"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  section: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66667 2.33333H12.75M8.66667 5.25H12.75M8.66667 8.75H12.75M8.66667 11.6667H12.75M2.83333 8.16667H5.75C6.07217 8.16667 6.33333 8.42783 6.33333 8.75V11.6667C6.33333 11.9888 6.07217 12.25 5.75 12.25H2.83333C2.51117 12.25 2.25 11.9888 2.25 11.6667V8.75C2.25 8.42783 2.51117 8.16667 2.83333 8.16667ZM2.83333 1.75H5.75C6.07217 1.75 6.33333 2.01117 6.33333 2.33333V5.25C6.33333 5.57217 6.07217 5.83333 5.75 5.83333H2.83333C2.51117 5.83333 2.25 5.57217 2.25 5.25V2.33333C2.25 2.01117 2.51117 1.75 2.83333 1.75Z"
        stroke="#FF4A00"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  contact_info: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_788_522)">
        <path
          d="M6.99984 4.66602V6.99935M6.99984 9.33268H7.00567M12.8332 6.99935C12.8332 10.221 10.2215 12.8327 6.99984 12.8327C3.77818 12.8327 1.1665 10.221 1.1665 6.99935C1.1665 3.77769 3.77818 1.16602 6.99984 1.16602C10.2215 1.16602 12.8332 3.77769 12.8332 6.99935Z"
          stroke="#6A32EE"
          stroke-width="0.875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_788_522">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  checkbox: <CheckSquareOutlined />,
  multiple_field: <FileTextOutlined />,
  legal: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66634 1.16797V4.66797H12.1663M9.83301 7.58464H5.16634M9.83301 9.91797H5.16634M6.33301 5.2513H5.16634M8.95801 1.16797H3.99967C3.69026 1.16797 3.39351 1.29089 3.17472 1.50968C2.95592 1.72847 2.83301 2.02522 2.83301 2.33464V11.668C2.83301 11.9774 2.95592 12.2741 3.17472 12.4929C3.39351 12.7117 3.69026 12.8346 3.99967 12.8346H10.9997C11.3091 12.8346 11.6058 12.7117 11.8246 12.4929C12.0434 12.2741 12.1663 11.9774 12.1663 11.668V4.3763L8.95801 1.16797Z"
        stroke="#F504FD"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  country: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_788_528)">
        <path
          d="M12.8332 6.99935C12.8332 10.221 10.2215 12.8327 6.99984 12.8327M12.8332 6.99935C12.8332 3.77769 10.2215 1.16602 6.99984 1.16602M12.8332 6.99935H1.1665M6.99984 12.8327C3.77818 12.8327 1.1665 10.221 1.1665 6.99935M6.99984 12.8327C8.45892 11.2353 9.28811 9.16233 9.33317 6.99935C9.28811 4.83637 8.45892 2.76339 6.99984 1.16602M6.99984 12.8327C5.54076 11.2353 4.71156 9.16233 4.6665 6.99935C4.71156 4.83637 5.54076 2.76339 6.99984 1.16602M1.1665 6.99935C1.1665 3.77769 3.77818 1.16602 6.99984 1.16602"
          stroke="#6A32EE"
          stroke-width="0.875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_788_528">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  ai_statement: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.94889 2.57525H3.07849C2.7852 2.57525 2.50393 2.69176 2.29655 2.89914C2.08916 3.10653 1.97266 3.3878 1.97266 3.68108V11.4219C1.97266 11.7152 2.08916 11.9964 2.29655 12.2038C2.50393 12.4112 2.7852 12.5277 3.07849 12.5277H10.8193C11.1126 12.5277 11.3939 12.4112 11.6012 12.2038C11.8086 11.9964 11.9251 11.7152 11.9251 11.4219V7.55149M11.0958 1.74588C11.3157 1.52592 11.614 1.40234 11.9251 1.40234C12.2362 1.40234 12.5345 1.52592 12.7545 1.74588C12.9745 1.96584 13.098 2.26418 13.098 2.57525C13.098 2.88633 12.9745 3.18466 12.7545 3.40463L7.5018 8.65732L5.29015 9.21023L5.84306 6.99857L11.0958 1.74588Z"
        stroke="#3538CD"
        stroke-width="0.88"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  statement: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66667 2.33333H12.75M8.66667 5.25H12.75M8.66667 8.75H12.75M8.66667 11.6667H12.75M2.83333 8.16667H5.75C6.07217 8.16667 6.33333 8.42783 6.33333 8.75V11.6667C6.33333 11.9888 6.07217 12.25 5.75 12.25H2.83333C2.51117 12.25 2.25 11.9888 2.25 11.6667V8.75C2.25 8.42783 2.51117 8.16667 2.83333 8.16667ZM2.83333 1.75H5.75C6.07217 1.75 6.33333 2.01117 6.33333 2.33333V5.25C6.33333 5.57217 6.07217 5.83333 5.75 5.83333H2.83333C2.51117 5.83333 2.25 5.57217 2.25 5.25V2.33333C2.25 2.01117 2.51117 1.75 2.83333 1.75Z"
        stroke="#FF4A00"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  video: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3332 4.66667L9.83317 7L13.3332 9.33333V4.66667Z"
        stroke="#FF4A00"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.6665 3.5H2.83317C2.18884 3.5 1.6665 4.02233 1.6665 4.66667V9.33333C1.6665 9.97767 2.18884 10.5 2.83317 10.5H8.6665C9.31084 10.5 9.83317 9.97767 9.83317 9.33333V4.66667C9.83317 4.02233 9.31084 3.5 8.6665 3.5Z"
        stroke="#FF4A00"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  AI_graph: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_788_573)">
        <path
          d="M1.94434 1.65234V12.1523H12.4443M11.2777 5.15234L8.361 8.06901L6.02767 5.73568L4.27767 7.48568"
          stroke="#2A2DEB"
          stroke-width="0.875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_788_573">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  AI_image: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 7.0013V11.0846C12.25 11.3941 12.1271 11.6908 11.9083 11.9096C11.6895 12.1284 11.3928 12.2513 11.0833 12.2513H2.91667C2.60725 12.2513 2.3105 12.1284 2.09171 11.9096C1.87292 11.6908 1.75 11.3941 1.75 11.0846V2.91797C1.75 2.60855 1.87292 2.3118 2.09171 2.09301C2.3105 1.87422 2.60725 1.7513 2.91667 1.7513H7M9.33333 2.91797H12.8333M11.0833 1.16797V4.66797M12.25 8.7513L10.4498 6.95114C10.2311 6.73242 9.93436 6.60955 9.625 6.60955C9.31564 6.60955 9.01895 6.73242 8.80017 6.95114L3.5 12.2513M6.41667 5.2513C6.41667 5.89563 5.89433 6.41797 5.25 6.41797C4.60567 6.41797 4.08333 5.89563 4.08333 5.2513C4.08333 4.60697 4.60567 4.08464 5.25 4.08464C5.89433 4.08464 6.41667 4.60697 6.41667 5.2513Z"
        stroke="#2A2DEB"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  file_upload: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33333 12.8346H10.5C10.8094 12.8346 11.1062 12.7117 11.325 12.4929C11.5437 12.2741 11.6667 11.9774 11.6667 11.668V4.3763L8.45833 1.16797H3.5C3.19058 1.16797 2.89383 1.29089 2.67504 1.50968C2.45625 1.72847 2.33333 2.02522 2.33333 2.33464V4.66797M8.16667 1.16797V4.66797H11.6667M1.75 8.7513H5.25M3.5 7.0013V10.5013"
        stroke="#ED1D24"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  yes_no: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49984 8.7513L8.6665 9.91797L10.9998 7.58464M2.83317 9.33463C2.1915 9.33463 1.6665 8.80964 1.6665 8.16797V2.33464C1.6665 1.69297 2.1915 1.16797 2.83317 1.16797H8.6665C9.30817 1.16797 9.83317 1.69297 9.83317 2.33464M6.33317 4.66797H12.1665C12.8108 4.66797 13.3332 5.1903 13.3332 5.83464V11.668C13.3332 12.3123 12.8108 12.8346 12.1665 12.8346H6.33317C5.68884 12.8346 5.1665 12.3123 5.1665 11.668V5.83464C5.1665 5.1903 5.68884 4.66797 6.33317 4.66797Z"
        stroke="#0BBD35"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.19188 2.98603C4.02217 2.8121 3.7436 2.80868 3.56967 2.97839C3.39574 3.14809 3.39232 3.42667 3.56203 3.60059L4.19188 2.98603ZM5.10574 5.1827C5.27544 5.35663 5.55402 5.36006 5.72794 5.19035C5.90187 5.02064 5.90529 4.74207 5.73559 4.56814L5.10574 5.1827ZM3.56583 6.44522C3.394 6.61705 3.394 6.89564 3.56583 7.06747C3.73766 7.2393 4.01625 7.2393 4.18808 7.06747L3.56583 6.44522ZM7.69833 3.55722C7.87016 3.38539 7.87016 3.1068 7.69833 2.93497C7.5265 2.76314 7.24791 2.76314 7.07608 2.93497L7.69833 3.55722ZM6.50964 4.12366L6.19852 3.81253L6.19852 3.81253L6.50964 4.12366ZM5.86996 4.14109L5.55883 4.45222L6.18108 5.07447L6.49221 4.76334L5.86996 4.14109ZM4.75452 5.87878L4.44339 5.56766L4.44339 5.56766L4.75452 5.87878ZM5.29606 5.95949L5.60719 5.64837L4.98493 5.02611L4.67381 5.33724L5.29606 5.95949ZM3.56203 3.60059L5.10574 5.1827L5.73559 4.56814L4.19188 2.98603L3.56203 3.60059ZM6.82077 4.43478L7.69833 3.55722L7.07608 2.93497L6.19852 3.81253L6.82077 4.43478ZM6.49221 4.76334L6.82077 4.43478L6.19852 3.81253L5.86996 4.14109L6.49221 4.76334ZM4.18808 7.06747L5.06564 6.18991L4.44339 5.56766L3.56583 6.44522L4.18808 7.06747ZM5.06564 6.18991L5.29606 5.95949L4.67381 5.33724L4.44339 5.56766L5.06564 6.18991Z"
        fill="#0BBD35"
      />
    </svg>
  ),
  AI_video: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8332 4.66667L9.33317 7L12.8332 9.33333V4.66667Z"
        stroke="#2A2DEB"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.1665 3.5H2.33317C1.68884 3.5 1.1665 4.02233 1.1665 4.66667V9.33333C1.1665 9.97767 1.68884 10.5 2.33317 10.5H8.1665C8.81084 10.5 9.33317 9.97767 9.33317 9.33333V4.66667C9.33317 4.02233 8.81084 3.5 8.1665 3.5Z"
        stroke="#2A2DEB"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.31702 5.83789L5.07176 6.58657C5.0592 6.62545 5.03759 6.6608 5.00871 6.6897C4.97983 6.7186 4.9445 6.74024 4.90563 6.75283L4.15527 6.99963L4.90395 7.24489C4.94283 7.25745 4.97818 7.27906 5.00708 7.30794C5.03599 7.33682 5.05762 7.37215 5.07021 7.41102L5.31702 8.16138L5.56227 7.4127C5.57483 7.37382 5.59644 7.33847 5.62532 7.30957C5.6542 7.28066 5.68953 7.25903 5.7284 7.24644L6.47876 6.99963L5.73008 6.75438C5.6912 6.74182 5.65585 6.72021 5.62695 6.69133C5.59805 6.66245 5.57641 6.62712 5.56382 6.58825L5.31702 5.83789Z"
        stroke="#2A2DEB"
        stroke-width="0.88"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  payment_method: <CreditCardOutlined />,
  subscription: <DollarOutlined />,
  language: <GlobalOutlined />,
  code_snippets: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4998 9.33203L12.8332 6.9987L10.4998 4.66536M3.49984 4.66536L1.1665 6.9987L3.49984 9.33203M8.45817 2.33203L5.5415 11.6654"
        stroke="#ED1D24"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  rating: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3055 1.76562L9.108 5.41729L13.1388 6.00646L10.2222 8.84729L10.9105 12.8606L7.3055 10.9648L3.7005 12.8606L4.38883 8.84729L1.47217 6.00646L5.503 5.41729L7.3055 1.76562Z"
        stroke="#191B33"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  date: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83333 1.16797V3.5013M5.16667 1.16797V3.5013M2.25 5.83464H12.75M5.16667 8.16797H5.1725M7.5 8.16797H7.50583M9.83333 8.16797H9.83917M5.16667 10.5013H5.1725M7.5 10.5013H7.50583M9.83333 10.5013H9.83917M3.41667 2.33464H11.5833C12.2277 2.33464 12.75 2.85697 12.75 3.5013V11.668C12.75 12.3123 12.2277 12.8346 11.5833 12.8346H3.41667C2.77233 12.8346 2.25 12.3123 2.25 11.668V3.5013C2.25 2.85697 2.77233 2.33464 3.41667 2.33464Z"
        stroke="#0BBD35"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  facebook: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3058 1.76562H9.55583C8.78228 1.76563 8.04041 2.07292 7.49343 2.6199C6.94645 3.16688 6.63916 3.90874 6.63916 4.68229V6.43229H4.88916V8.76562H6.63916V13.4323H8.97249V8.76562H10.7225L11.3058 6.43229H8.97249V4.68229C8.97249 4.52758 9.03395 4.37921 9.14335 4.26981C9.25274 4.16042 9.40112 4.09896 9.55583 4.09896H11.3058V1.76562Z"
        stroke="#191B33"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  twitter: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_570_3788)">
        <path
          d="M13.6393 2.9323C13.6393 2.9323 13.231 4.1573 12.4727 4.91564C13.406 10.749 6.98932 15.0073 1.97266 11.6823C3.25599 11.7406 4.53932 11.3323 5.47266 10.5156C2.55599 9.64064 1.09766 6.19897 2.55599 3.51564C3.83932 5.0323 5.82266 5.9073 7.80599 5.84897C7.28099 3.39897 10.1393 1.99897 11.8893 3.6323C12.531 3.6323 13.6393 2.9323 13.6393 2.9323Z"
          stroke="#191B33"
          stroke-width="0.875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_570_3788">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.805664 0.597656)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  instagram: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5138 4.39062H10.5197M4.38883 1.76562H10.2222C11.833 1.76562 13.1388 3.07146 13.1388 4.68229V10.5156C13.1388 12.1265 11.833 13.4323 10.2222 13.4323H4.38883C2.778 13.4323 1.47217 12.1265 1.47217 10.5156V4.68229C1.47217 3.07146 2.778 1.76562 4.38883 1.76562ZM9.63883 7.23146C9.71082 7.71693 9.6279 8.21275 9.40186 8.64838C9.17582 9.08402 8.81817 9.43728 8.37978 9.65793C7.9414 9.87859 7.4446 9.95539 6.96004 9.87742C6.47549 9.79945 6.02786 9.57067 5.68082 9.22364C5.33379 8.8766 5.10501 8.42897 5.02704 7.94442C4.94907 7.45986 5.02587 6.96306 5.24652 6.52468C5.46718 6.08629 5.82044 5.72864 6.25608 5.5026C6.69171 5.27656 7.18752 5.19363 7.673 5.26562C8.16821 5.33906 8.62666 5.56981 8.98066 5.9238C9.33465 6.2778 9.5654 6.73625 9.63883 7.23146Z"
        stroke="#191B33"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  linkedin: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.63883 5.26563C10.5671 5.26563 11.4573 5.63437 12.1137 6.29075C12.7701 6.94713 13.1388 7.83737 13.1388 8.76563V12.849H10.8055V8.76563C10.8055 8.45621 10.6826 8.15946 10.4638 7.94067C10.245 7.72188 9.94825 7.59896 9.63883 7.59896C9.32942 7.59896 9.03267 7.72188 8.81388 7.94067C8.59508 8.15946 8.47217 8.45621 8.47217 8.76563V12.849H6.13883V8.76563C6.13883 7.83737 6.50758 6.94713 7.16396 6.29075C7.82034 5.63437 8.71058 5.26563 9.63883 5.26563Z"
        stroke="#191B33"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.8055 5.84896H1.47217V12.849H3.8055V5.84896Z"
        stroke="#191B33"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.63883 4.09896C3.28317 4.09896 3.8055 3.57662 3.8055 2.93229C3.8055 2.28796 3.28317 1.76562 2.63883 1.76562C1.9945 1.76562 1.47217 2.28796 1.47217 2.93229C1.47217 3.57662 1.9945 4.09896 2.63883 4.09896Z"
        stroke="#191B33"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  youtube: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_788_668)">
        <path
          d="M13.2492 4.14875C13.1112 3.63945 12.7047 3.23839 12.1883 3.10228C11.2525 2.85491 7.5 2.85491 7.5 2.85491C7.5 2.85491 3.7475 2.85491 2.81167 3.10228C2.29538 3.23839 1.88874 3.63945 1.75075 4.14875C1.5 5.07183 1.5 6.99777 1.5 6.99777C1.5 6.99777 1.5 8.92368 1.75075 9.84684C1.88874 10.3561 2.29538 10.7571 2.81167 10.8933C3.7475 11.1406 7.5 11.1406 7.5 11.1406C7.5 11.1406 11.2525 11.1406 12.1883 10.8933C12.7047 10.7571 13.1112 10.3561 13.2492 9.84684C13.5 8.92368 13.5 6.99777 13.5 6.99777C13.5 6.99777 13.5 5.07183 13.2492 4.14875Z"
          fill="#ED1D24"
        />
        <path
          d="M6.27295 8.74609L9.40931 6.99757L6.27295 5.24889V8.74609Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_788_668">
          <rect
            width="12"
            height="8.28571"
            fill="white"
            transform="translate(1.5 2.85547)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  redirect_to_url: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_788_644)">
        <path
          d="M5.94417 9.81771H4.77751C4.00396 9.81771 3.26209 9.51042 2.71511 8.96344C2.16813 8.41646 1.86084 7.67459 1.86084 6.90104C1.86084 6.12749 2.16813 5.38563 2.71511 4.83865C3.26209 4.29167 4.00396 3.98438 4.77751 3.98438H5.94417M9.44417 3.98438H10.6108C11.3844 3.98438 12.1263 4.29167 12.6732 4.83865C13.2202 5.38563 13.5275 6.12749 13.5275 6.90104C13.5275 7.67459 13.2202 8.41646 12.6732 8.96344C12.1263 9.51042 11.3844 9.81771 10.6108 9.81771H9.44417M5.36084 6.90104H10.0275"
          stroke="#F504FD"
          stroke-width="0.875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_788_644">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  long_text: (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 3.5H12.75M2.25 7H12.75M2.25 10.5H12.75"
        stroke="#FF4A00"
        stroke-width="0.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  calendly: (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.72448 9.80551C9.33624 10.1482 8.85138 10.5745 7.97224 10.5745H7.44653C6.81036 10.5745 6.23172 10.3446 5.81755 9.92774C5.41297 9.52044 5.19025 8.96301 5.19025 8.35789V7.64256C5.19025 7.03744 5.41297 6.48001 5.81755 6.07271C6.23172 5.65587 6.81036 5.42626 7.44653 5.42626H7.97224C8.85245 5.42626 9.33624 5.85228 9.72448 6.19493C10.1276 6.54818 10.4754 6.85693 11.4028 6.85693C11.5443 6.85689 11.6855 6.84567 11.8252 6.82337L11.822 6.81524C11.7664 6.67805 11.7013 6.5449 11.627 6.41678L11.0071 5.3489C10.7276 4.8675 10.3256 4.46775 9.84151 4.18983C9.35741 3.9119 8.80828 3.7656 8.2493 3.76563H7.00927C6.4503 3.7656 5.90117 3.9119 5.41707 4.18983C4.93297 4.46775 4.53096 4.8675 4.25144 5.3489L3.6316 6.41678C3.35218 6.89823 3.20508 7.44433 3.20508 8.00022C3.20508 8.55612 3.35218 9.10222 3.6316 9.58367L4.25144 10.6515C4.53096 11.133 4.93297 11.5327 5.41707 11.8106C5.90117 12.0885 6.4503 12.2348 7.00927 12.2348H8.2493C8.80828 12.2348 9.35741 12.0885 9.84151 11.8106C10.3256 11.5327 10.7276 11.133 11.0071 10.6515L11.627 9.58544C11.7013 9.45731 11.7664 9.32416 11.822 9.18697L11.8252 9.1792C11.6856 9.15614 11.5443 9.14432 11.4028 9.14387C10.4754 9.14387 10.1276 9.45085 9.72448 9.80587"
        fill="#006BFF"
      />
      <path
        d="M7.97198 6.07422H7.44627C6.47797 6.07422 5.8418 6.762 5.8418 7.6423V8.35763C5.8418 9.23794 6.47797 9.92572 7.44627 9.92572H7.97198C9.38286 9.92572 9.27204 8.49505 11.4026 8.49505C11.6044 8.49482 11.8059 8.51327 12.0043 8.55016C12.0689 8.18618 12.0689 7.81375 12.0043 7.44978C11.8059 7.48649 11.6044 7.50494 11.4026 7.50489C9.27133 7.50489 9.38286 6.07422 7.97198 6.07422Z"
        fill="#006BFF"
      />
      <path
        d="M13.2286 9.07345C12.8658 8.80918 12.4474 8.6303 12.0049 8.55028C12.0049 8.55417 12.0049 8.55735 12.0049 8.56088C11.9668 8.7718 11.9073 8.97833 11.8273 9.17731C12.1928 9.2336 12.5396 9.37564 12.8389 9.59167C12.8389 9.5945 12.8371 9.59803 12.8361 9.60121C12.4508 10.8419 11.6308 11.904 10.5251 12.5946C9.41933 13.2851 8.1012 13.5583 6.81012 13.3646C5.51904 13.1709 4.34067 12.523 3.48906 11.5387C2.63745 10.5544 2.16911 9.29895 2.16911 8.00045C2.16911 6.70195 2.63745 5.44652 3.48906 4.46222C4.34067 3.47792 5.51904 2.83005 6.81012 2.6363C8.1012 2.44255 9.41933 2.71578 10.5251 3.40634C11.6308 4.09691 12.4508 5.15901 12.8361 6.39969C12.8361 6.40287 12.8379 6.4064 12.8389 6.40923C12.5396 6.62529 12.1928 6.76722 11.8273 6.82324C11.9072 7.02249 11.9667 7.22924 12.0049 7.44037C12.0047 7.44366 12.0047 7.44697 12.0049 7.45026C12.4473 7.37038 12.8658 7.19161 13.2286 6.92745C13.5774 6.67063 13.5099 6.38062 13.457 6.20894C13.0257 4.81787 12.107 3.62683 10.8676 2.85224C9.62833 2.07766 8.15072 1.77093 6.70331 1.98779C5.2559 2.20466 3.93475 2.93072 2.97994 4.03406C2.02512 5.13739 1.5 6.54477 1.5 8.00045C1.5 9.45613 2.02512 10.8635 2.97994 11.9668C3.93475 13.0702 5.2559 13.7962 6.70331 14.0131C8.15072 14.23 9.62833 13.9232 10.8676 13.1487C12.107 12.3741 13.0257 11.183 13.457 9.79196C13.5099 9.62028 13.5774 9.33026 13.2286 9.07345Z"
        fill="#006BFF"
      />
      <path
        d="M11.8249 6.82343C11.6853 6.84649 11.544 6.85831 11.4025 6.85876C10.4751 6.85876 10.1273 6.55178 9.72452 6.19676C9.33593 5.85411 8.85213 5.42773 7.97193 5.42773H7.44622C6.80969 5.42773 6.23141 5.6577 5.81724 6.07454C5.41266 6.48184 5.18994 7.03927 5.18994 7.64439V8.35972C5.18994 8.96484 5.41266 9.52227 5.81724 9.92957C6.23141 10.3464 6.80969 10.576 7.44622 10.576H7.97193C8.85213 10.576 9.33593 10.15 9.72452 9.80734C10.1273 9.45409 10.4751 9.14535 11.4025 9.14535C11.544 9.14538 11.6852 9.15661 11.8249 9.17891C11.905 8.97999 11.9646 8.77344 12.0025 8.56249C12.0027 8.55896 12.0027 8.55542 12.0025 8.55189C11.804 8.5153 11.6026 8.49697 11.4008 8.49713C9.2695 8.49713 9.38104 9.92815 7.97015 9.92815H7.44445C6.47615 9.92815 5.83997 9.24002 5.83997 8.35972V7.64297C5.83997 6.76267 6.47615 6.07489 7.44445 6.07489H7.97015C9.38104 6.07489 9.27021 7.5052 11.4008 7.5052C11.6026 7.50555 11.804 7.48722 12.0025 7.45045C12.0025 7.44727 12.0025 7.44409 12.0025 7.44056C11.9643 7.22943 11.9048 7.02268 11.8249 6.82343Z"
        fill="#0AE8F0"
      />
      <path
        d="M11.8249 6.82343C11.6853 6.84649 11.544 6.85831 11.4025 6.85876C10.4751 6.85876 10.1273 6.55178 9.72452 6.19676C9.33593 5.85411 8.85213 5.42773 7.97193 5.42773H7.44622C6.80969 5.42773 6.23141 5.6577 5.81724 6.07454C5.41266 6.48184 5.18994 7.03927 5.18994 7.64439V8.35972C5.18994 8.96484 5.41266 9.52227 5.81724 9.92957C6.23141 10.3464 6.80969 10.576 7.44622 10.576H7.97193C8.85213 10.576 9.33593 10.15 9.72452 9.80734C10.1273 9.45409 10.4751 9.14535 11.4025 9.14535C11.544 9.14538 11.6852 9.15661 11.8249 9.17891C11.905 8.97999 11.9646 8.77344 12.0025 8.56249C12.0027 8.55896 12.0027 8.55542 12.0025 8.55189C11.804 8.5153 11.6026 8.49697 11.4008 8.49713C9.2695 8.49713 9.38104 9.92815 7.97015 9.92815H7.44445C6.47615 9.92815 5.83997 9.24002 5.83997 8.35972V7.64297C5.83997 6.76267 6.47615 6.07489 7.44445 6.07489H7.97015C9.38104 6.07489 9.27021 7.5052 11.4008 7.5052C11.6026 7.50555 11.804 7.48722 12.0025 7.45045C12.0025 7.44727 12.0025 7.44409 12.0025 7.44056C11.9643 7.22943 11.9048 7.02268 11.8249 6.82343Z"
        fill="#0AE8F0"
      />
    </svg>
  ),
};

export const QUESTION_OBJECTS = {
  email: {
    type: "email",
    label: "Email",
    icon: QUESTION_ICONS.email,
    color: "#F9F5FF",
    textColor: "#6A32EE",
  },
  phone_number: {
    type: "phone_number",
    label: "Phone Number",
    icon: QUESTION_ICONS.phone_number,
    color: "#F9F5FF",
    textColor: "#6A32EE",
  },
  contact_info: {
    type: "contact_info",
    label: "Contact Info",
    icon: QUESTION_ICONS.contact_info,
    color: "#F9F5FF",
    textColor: "#6A32EE",
  },
  country: {
    type: "country",
    label: "Country",
    icon: QUESTION_ICONS.country,
    color: "#F9F5FF",
    textColor: "#6A32EE",
  },
  multiple_choice: {
    type: "multiple_choice",
    label: "Multiple Choice",
    icon: QUESTION_ICONS.multiple_choice,
    color: "#ECFDF3",
    textColor: "#0BBD35",
  },
  dropdown: {
    type: "dropdown",
    label: "Dropdown",
    icon: QUESTION_ICONS.dropdown,
    color: "#ECFDF3",
    textColor: "#0BBD35",
  },
  legal: {
    type: "legal",
    label: "Legal",
    icon: QUESTION_ICONS.legal,
    color: "#FFF2FF",
    textColor: "#F504FD",
  },
  short_text: {
    type: "short_text",
    label: "Short Text",
    icon: QUESTION_ICONS.short_text,
    color: "#FFFAEB",
    textColor: "#B54708",
  },
  section: {
    type: "section",
    label: "Section",
    icon: QUESTION_ICONS.section,
    color: "#FFE2A7",
    color: "#FFFAEB",
    textColor: "#B54708",
  },
  checkbox: {
    type: "checkbox",
    label: "Checkbox",
    icon: <CheckSquareOutlined />,
    color: "#8BC34A",
  },

  ai_statement: {
    type: "ai_statement",
    label: "AI statement",
    icon: QUESTION_ICONS.ai_statement,
    color: "#EEF4FF",
    textColor: "#2A2DEB",
  },
};
export const QUESTION_TYPES = {
  MULTIPLE_CHOICE: "multiple_choice",
  SHORT_TEXT: "short_text",
  EMAIL: "email",
  DROPDOWN: "dropdown",
  PHONE_NUMBER: "phone_number",
  SECTION: "section",
  CONTACT_INFORMATION: "contact_info",
  CHECKBOX: "checkbox",
  MULTIPLE_FIELD: "multiple_field",
  LEGAL: "legal",
  COUNTRY: "country",
  AI_STATEMENT: "ai_statement",
  STATEMENT: "statement",
  VIDEO: "video",
  AI_GRAPH: "AI_graph",
  AI_IMAGE: "AI_image",
  FILE_UPLOAD: "file_upload",
  YES_NO: "yes_no",
  AI_VIDEO: "AI_video",
  LANGUAGE_PREFERENCE: "language",
  SUBSCRIPTION_TYPE: "subscription",
  PAYMENT_TYPE: "payment_method",
  REDIRECT: "redirect_to_url",
  RATING: "rating",
  DATE: "date",
  CODES: "code_snippets",
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedin",
  YOUTUBE: "youtube",
  WEBSITE: "website",
  LONG_TEXT: "long_text",
  CALENDLY: "calendly",
};

export const FIELD_TYPE_GROUPS = {
  Recommended: [
    QUESTION_TYPES.SHORT_TEXT,
    QUESTION_TYPES.MULTIPLE_CHOICE,
    QUESTION_TYPES.DROPDOWN,
    QUESTION_TYPES.AI_STATEMENT,
  ],
  "Contact Info": [
    QUESTION_TYPES.EMAIL,
    QUESTION_TYPES.PHONE_NUMBER,
    QUESTION_TYPES.CONTACT_INFORMATION,
    QUESTION_TYPES.COUNTRY,
  ],

  Choices: [
    QUESTION_TYPES.MULTIPLE_CHOICE,
    QUESTION_TYPES.DROPDOWN,
    QUESTION_TYPES.YES_NO,
    QUESTION_TYPES.DATE,
  ],
  "AI Features": [
    QUESTION_TYPES.AI_STATEMENT,
    QUESTION_TYPES.AI_GRAPH,
    QUESTION_TYPES.AI_IMAGE,
    QUESTION_TYPES.AI_VIDEO,
  ],
  "Text and Video": [
    QUESTION_TYPES.SHORT_TEXT,
    QUESTION_TYPES.LONG_TEXT,
    QUESTION_TYPES.VIDEO,
    QUESTION_TYPES.SECTION,
  ],

  "Welcome and End Screens": [QUESTION_TYPES.LEGAL, QUESTION_TYPES.REDIRECT],

  "Embeddings and Uploads": [
    QUESTION_TYPES.CODES,
    QUESTION_TYPES.YOUTUBE,
    QUESTION_TYPES.FILE_UPLOAD,
    QUESTION_TYPES.CALENDLY,
  ],
};

export const FIELD_TYPE_OPTIONS = {
  [QUESTION_TYPES.MULTIPLE_CHOICE]: {
    type: QUESTION_TYPES.MULTIPLE_CHOICE,
    label: "Multiple Choice",
    title: "",
    properties: {
      description: "",
      choices: [{ id: uuidv4(), label: "" }],
      allow_multiple_selection: false,
    },
    validations: {
      required: false,
    },
    attachment: {
      link: "",
      type: "image",
      placement: "right",
    },
  },
  [QUESTION_TYPES.SHORT_TEXT]: {
    type: QUESTION_TYPES.SHORT_TEXT,
    label: "Short Text",
    title: "",
    properties: {
      description: "",
    },
    validations: {
      required: false,
    },
    attachment: {
      link: "",
      type: "image",
      placement: "right",
    },
  },
  [QUESTION_TYPES.EMAIL]: {
    type: QUESTION_TYPES.EMAIL,
    label: "Email",
    title: "",
    properties: {
      description: "",
    },
  },
  [QUESTION_TYPES.DROPDOWN]: {
    type: QUESTION_TYPES.DROPDOWN,
    label: "Dropdown",
    title: "",
    properties: {
      description: "",
    },
  },
  [QUESTION_TYPES.PHONE_NUMBER]: {
    type: QUESTION_TYPES.PHONE_NUMBER,
    label: "Phone Number",
    title: "",
    properties: {
      description: "",
    },
  },
  [QUESTION_TYPES.SECTION]: {
    type: QUESTION_TYPES.SECTION,
    label: "Section",
    title: "",
    properties: {
      description: "",
    },
  },
  [QUESTION_TYPES.CONTACT_INFORMATION]: {
    type: QUESTION_TYPES.CONTACT_INFORMATION,
    label: "Contact Info",
    title: "",
    properties: {
      description: "",
    },
  },
  [QUESTION_TYPES.LEGAL]: {
    type: QUESTION_TYPES.LEGAL,
    label: "Legal",
    title: "",
    properties: {
      description: "",
    },
  },
  [QUESTION_TYPES.COUNTRY]: {
    type: QUESTION_TYPES.COUNTRY,
    label: "Country",
    title: "",
    properties: {
      description: "",
    },
  },
  [QUESTION_TYPES.AI_STATEMENT]: {
    type: QUESTION_TYPES.AI_STATEMENT,
    label: "AI statement",
    title: "",
    properties: {
      description: "",
    },
  },
  /*   [QUESTION_TYPES.STATEMENT]: {
    type: QUESTION_TYPES.STATEMENT,
    label: "Statement",
  }, */
};

export const FIELD_TYPE_SETTINGS = {
  [QUESTION_TYPES.SHORT_TEXT]: {
    validations: {
      required: "Switch",
    },
    attachment: {
      link: "Image",
      placement: "Placement",
      type: "image",
    },
  },
  [QUESTION_TYPES.MULTIPLE_CHOICE]: {
    properties: {
      allow_multiple_selection: "Switch",
    },
    validations: {
      required: "Switch",
    },
    attachment: {
      link: "Image",
      placement: "Placement",
      type: "image",
    },
  },
  [QUESTION_TYPES.EMAIL]: {
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.PHONE_NUMBER]: {
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.DROPDOWN]: {
    properties: {
      choices: "ArrayInput",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.SECTION]: {},
  [QUESTION_TYPES.CONTACT_INFORMATION]: {
    properties: {
      fields: "ArrayObject",
    },
  },
  [QUESTION_TYPES.CHECKBOX]: {
    properties: {
      checkbox_label: "ShortText",
    },
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.COUNTRY]: {
    validations: {
      required: "Switch",
    },
  },
  [QUESTION_TYPES.LEGAL]: {},
  [QUESTION_TYPES.AI_STATEMENT]: {
    title: "AiStatement",
  },
  [QUESTION_TYPES.STATEMENT]: {},
};
export const SETTING_CONTEXT = {
  FIELD: "Field",
  GLOBAL: "Global",
};
