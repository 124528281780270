import React, { useCallback, useEffect, useRef } from "react";

import { v4 as uuidv4 } from "uuid";

import { Input } from "../../ui/input.tsx";
import { ResizableTextarea } from "../../ui/resizable-textarea.tsx";
import FormButton from "../FormButton.tsx";

import useStyles from "../../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext";

import { getLetterForIndex } from "../../../Utils/string";

import { cn } from "../../../lib/utils.js";

import { XIcon } from "lucide-react";

function MultipleChoice() {
  const { getStyles } = useStyles();
  const saveTimeoutRef = useRef<any>(null);
  const {
    theme,
    editorField,
    setEditorField,
    fields,
    setFields,
    setChanged,
    device,
  } = useFormEditorContext();

  const updateField = (fieldId, key, subkey, value) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === fieldId) {
          if (subkey) {
            return {
              ...field,
              [key]: {
                ...field[key],
                [subkey]: value,
              },
            };
          } else {
            return {
              ...field,
              [key]: value,
            };
          }
        }
        return field;
      });
      return updatedFields;
    });

    setEditorField((prev) => {
      if (subkey) {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [subkey]: value,
          },
        };
      } else {
        return {
          ...prev,
          [key]: value,
        };
      }
    });
  };

  const scheduleSave = (updatedField) => {
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(() => {
      saveChanges(updatedField);
    }, 500); // Debounce save for 500ms
  };

  const handleAddOption = () => {
    const newOption = {
      id: uuidv4(),
      label: "",
      value: "",
    };
    setEditorField((prev) => ({
      ...prev,
      properties: {
        ...prev.properties,
        choices: [...(prev.properties.choices || []), newOption],
      },
    }));
  };

  const saveChanges = useCallback(
    (updatedEditorField) => {
      setChanged(true);
      const newFields = [...fields];
      const index = newFields.findIndex((f) => f?.id === updatedEditorField.id);

      const cleanEditorField = {
        ...updatedEditorField,
        title: updatedEditorField?.title || "",
        properties: {
          ...updatedEditorField.properties,
          description: updatedEditorField.properties?.description || "",
        },
      };

      newFields[index] = Object.fromEntries(
        Object.entries(cleanEditorField).filter(([key, value]) => value != null)
      );

      setFields(newFields);
    },
    [fields, setFields, setChanged]
  );

  // Cleanup function to clear any pending timeouts
  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="text-left flex flex-col w-full max-w-[606px]">
      <ResizableTextarea
        placeholder="Add question title"
        value={editorField?.title || ""}
        onChange={(value) => updateField(editorField?.id, "title", null, value)}
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h2", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h2", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
      />
      <ResizableTextarea
        placeholder="Add question description (Optional)"
        value={editorField?.properties?.description || ""}
        onChange={(value) =>
          updateField(editorField?.id, "properties", "description", value)
        }
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h4", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h4", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
        className="mt-2"
      />
      <div
        className={cn(
          "grid gap-2",
          device === "mobile" ? "grid-cols-2 mt-4" : "grid-cols-3 mt-6"
        )}
      >
        {editorField?.properties?.choices?.map((option: any, index: number) => (
          <Choice
            id={option?.id}
            letter={getLetterForIndex(index)}
            label={option?.label}
            onChange={(field) => scheduleSave(field)}
            onKeyDown={handleAddOption}
            onRemove={(field) => scheduleSave(field)}
          />
        ))}
      </div>

      <div className="w-full mt-2">
        <button
          style={{
            color: theme?.answer_text_color,
          }}
          onClick={handleAddOption}
          className="flex justify-start text-sm font-medium underline"
        >
          Add Choice
        </button>
      </div>
      <FormButton className="mt-3">Next</FormButton>
    </div>
  );
}

export default MultipleChoice;

type ChoiceProps = {
  id: string;
  letter: string;
  label: string;
  onChange: (field: any) => void;
  onKeyDown: (optionId: string, value: string) => void;
  onRemove: (field: any) => void;
};

const Choice = ({
  id,
  letter,
  label,
  onChange,
  onKeyDown,
  onRemove,
}: ChoiceProps) => {
  const inputRef = useRef({});
  const { theme, setEditorField } = useFormEditorContext();

  const handleOnChange = useCallback((value) => {
    setEditorField((prev) => {
      const updatedField = {
        ...prev,
        properties: {
          ...prev.properties,
          choices: (prev.properties.choices || []).map((choice) =>
            choice.id === id
              ? { ...choice, label: value, value: value }
              : choice
          ),
        },
      };

      onChange(updatedField);
      return updatedField;
    });

    // Maintain focus after state update
    setTimeout(() => {
      if (inputRef.current[id]) {
        inputRef.current[id].focus();
      }
    }, 0);
  }, []);

  const handleOnRemove = useCallback(() => {
    setEditorField((prev) => {
      const updatedField = {
        ...prev,
        properties: {
          ...prev.properties,
          choices: (prev.properties.choices || []).filter(
            (choice) => choice.id !== id
          ),
        },
      };

      onRemove(updatedField);
      return updatedField;
    });
  }, []);

  return (
    <div className="flex flex-row items-center justify-between border border-[#DCDFE4] rounded-lg p-1 max-w-[200px] h-[40px]">
      <div className="flex items-center justify-center bg-[#F1F2F4] rounded-lg text-center w-8 h-8">
        <p
          style={{
            color: theme?.answer_text_color,
          }}
        >
          {letter}
        </p>
      </div>
      <Input
        key={id}
        ref={(el) => {
          inputRef.current[id] = el;
        }}
        value={label}
        onChange={(e) => handleOnChange(e.currentTarget.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onKeyDown(id, e.currentTarget.value);
          }
        }}
        style={{
          color: theme?.answer_text_color || theme?.text_color,
        }}
        autoFocus
        placeholder="choice"
        className="flex-1 bg-transparent border-none outline-none shadow-none w-full p-0 pl-3 text-left text-md focus-visible:ring-0 focus-visible:ring-offset-0"
      />
      <button
        className="bg-transparent hover:outline-none hover:border-none"
        onClick={handleOnRemove}
      >
        <XIcon className="w-[14px] h-[14px] mr-[4px]" />
      </button>
    </div>
  );
};
