import React from "react";

import { useSearchParams } from "react-router-dom";

import { Download, X } from "lucide-react";

import { Table } from "@tanstack/react-table";

import { Button } from "../ui/button.tsx";
import { Input } from "../ui/input.tsx";
import { DataTableFacetedFilter } from "./DataTableFilters.tsx";
import { DateRangePicker } from "../ui/date-range-picker.tsx";

import { format } from "date-fns";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

const Statuses = [
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "partial",
    label: "Partial",
  },
  {
    value: "started",
    label: "Started",
  },
];

export function DataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isFiltered = table.getState().columnFilters.length > 0;

  const query = searchParams.get("query") ?? "";
  const from = searchParams.get("from");
  const to = searchParams.get("to");

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Search responses..."
          value={query}
          onChange={(event) => {
            setSearchParams({ query: event.target.value });
          }}
          className="max-w-sm"
        />

        {table.getColumn("Status") && (
          <DataTableFacetedFilter
            column={table.getColumn("Status")}
            title="Status"
            options={Statuses}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <X className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <div className="flex flex-row items-center gap-2">
        <DateRangePicker
          date={{
            from: from ? new Date(from) : undefined,
            to: to ? new Date(to) : undefined,
          }}
          setDate={(date) => {
            console.log(date);
            if (!date) return;
            setSearchParams({
              // @ts-ignore
              from: date.from ? format(date.from, "yyyy-MM-dd") : "",
              // @ts-ignore
              to: date.to ? format(date.to, "yyyy-MM-dd") : "",
            });
          }}
        />
        <Button variant="outline" size="icon" disabled={true}>
          <Download className="w-3 h-3" />
        </Button>
      </div>
    </div>
  );
}
