import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import { Button, Tooltip } from "antd";
import { Flex, Space } from "antd";
import { useQuestionContext } from "../../Contexts/QuestionContext";
import { Grid } from "antd";
import { useFormContext } from "../../Contexts/FormContext";
import logoWithText from "../../assets/images/logoWithText.png";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";

const { useBreakpoint } = Grid;

export const FormFooter = () => {
  const { nextButtonPress, previous, formTheme } = useFormContext();
  const { theme } = useFormEditorContext();
  //console.log("theme : ", theme);
  const isFormEditor = location.pathname.includes("form-editor");
  const constTheme = isFormEditor ? theme : formTheme;

  return (
    <FormFooterFormat
      next={nextButtonPress}
      constTheme={constTheme}
      previous={previous}
    />
  );
  //   <Typography.Text>test footer</Typography.Text>;
};

export const FormFooterFormat = ({
  next = () => {},
  previous = () => {},
  showControls = true,
  constTheme,
  theme,
}) => {
  const { md: s } = useBreakpoint();

  return (
    <Flex
      className="fill-available"
      style={{
        margin: "1%",
        marginRight: "1%",
        justifyContent: s ? "flex-end" : "space-evenly",
      }}
      gap={"large"}
    >
      <Flex style={{ alignItems: "center" }}>
        <Typography.Text
          style={{ fontWeight: "normal", color: constTheme?.text_color }}
        >
          {/* Crafted with */}
          Powered by
          {/* <Typography.Text strong>BuildForm</Typography.Text> */}
        </Typography.Text>
        <img
          src={logoWithText}
          style={{ height: "1.5em", margin: "0 0.5em" }}
        />
      </Flex>
      {showControls && (
        <Space.Compact>
          <Tooltip title="Back">
            <Button
              style={{
                backgroundColor:
                  constTheme?.button_color || theme?.button_color,
                color:
                  constTheme?.button_text_color || theme?.button_text_color,
              }}
              // type="primary"
              icon={<ArrowUpOutlined />}
              onClick={previous}
            />
          </Tooltip>
          <Tooltip title="Next">
            <Button
              style={{
                backgroundColor:
                  constTheme?.button_color || theme?.button_color,
                color:
                  constTheme?.button_text_color || theme?.button_text_color,
              }}
              // type="primary"
              icon={<ArrowDownOutlined />}
              onClick={next}
            />
          </Tooltip>
        </Space.Compact>
      )}
    </Flex>
  );
};
