import React from "react";
import { useFormEditorContext } from "../../Contexts/FormEditorContext";
import { SiderFieldCard } from "../FormEditor/SiderFieldCard";
import { Reorder, motion } from "framer-motion";
import { AddFieldButton } from "../FormEditor/AddFieldButton";
import { sideFade } from "../../Constants/animations";

const ContentTab = ({ ai = false }) => {
  const { fields, setFields, isWelcomeIsEnabled, isEndIsEnabled } =
    useFormEditorContext();

  return (
    <>
      <div className="flex flex-col h-full pb-4 overflow-y-auto ">
        <div className="flex flex-col items-start justify-start gap-1 mb-1 text-start">
          {/* steps */}
          <div className="w-full mt-4">
            <div className="flex items-center gap-1 mb-1">
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_436_3211)">
                  <path
                    d="M1.1665 10.7666L6.99984 13.6833L12.8332 10.7666M1.1665 7.84993L6.99984 10.7666L12.8332 7.84993M6.99984 2.0166L1.1665 4.93327L6.99984 7.84993L12.8332 4.93327L6.99984 2.0166Z"
                    stroke="#191B33"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_436_3211">
                    <rect
                      width="14"
                      height="14"
                      fill="white"
                      transform="translate(0 0.849609)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <h4 className="text-sm font-medium text-[#191B33]">Steps</h4>
            </div>
            <p className="text-xs text-[#44546F] break-normal font-normal mb-3">
              The steps users will take to complete the form
            </p>
            <div className="flex flex-col w-full gap-1 overflow-y-auto min-h-max">
              {isWelcomeIsEnabled && (
                //<motion.div {...sideFade}>
                <SiderFieldCard
                  field={"welcome-screen"}
                  label={"Welcome screen"}
                />
                //  </motion.div>
              )}
              <Reorder.Group
                className="flex flex-col gap-1 reorder-ul fill-available"
                axis="y"
                values={fields}
                onReorder={setFields}
              >
                {fields?.map((item, index) => (
                  <Reorder.Item
                    key={item?.id}
                    value={item}
                    //  {...sideFade}
                    className="reorder-li fill-available"
                  >
                    <SiderFieldCard field={item} index={index + 1} />
                  </Reorder.Item>
                ))}
              </Reorder.Group>
              {isEndIsEnabled && (
                //   <motion.div {...sideFade}>
                <SiderFieldCard field={"end-screen"} label={"End screen"} />
                //  </motion.div>
              )}

              <AddFieldButton ai={ai} />
            </div>
          </div>
        </div>

        {/*  <div className="flex gap-2 mt-auto">
          <FormSaveButton />
           <FormDeleteButton />
        </div> */}
      </div>
    </>
  );
};

export default ContentTab;
