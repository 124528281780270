import React from "react";

// Components
import { Button } from "../ui/button.tsx";

import {
  ArrowUpDown,
  Mail,
  Phone,
  InfoIcon,
  Globe,
  Check,
  ChevronDown,
  File,
  PenLine,
  Loader,
  Tag,
  Calendar,
  Text,
} from "lucide-react";

export type HeaderTypes =
  | "status"
  | "phone_number"
  | "short_text"
  | "contact_info"
  | "email"
  | "dropdown"
  | "section"
  | "multiple_choice"
  | "checkbox"
  | "group"
  | "legal"
  | "country"
  | "ai_statement";

type DefaultHeaderTypes = "tags" | "date";

type HeaderProps = {
  title: string;
  type: DefaultHeaderTypes | HeaderTypes;
  onClick: () => void;
};

function Header({ title, type, onClick }: HeaderProps) {
  if (type === "phone_number")
    return <PhoneNumber title={title} onClick={onClick} />;

  if (type === "email") return <Email title={title} onClick={onClick} />;

  if (type === "short_text")
    return <ShortText title={title} onClick={onClick} />;

  if (type === "contact_info") return <Info title={title} onClick={onClick} />;

  if (type === "dropdown") return <Dropdown title={title} onClick={onClick} />;

  if (type === "multiple_choice")
    return <MultipleChoices title={title} onClick={onClick} />;

  if (type === "legal") return <Legal title={title} onClick={onClick} />;

  if (type === "country") return <Country title={title} onClick={onClick} />;

  if (type === "section") return <Section title={title} onClick={onClick} />;

  if (type === "status") return <Status title={title} onClick={onClick} />;

  if (type === "tags") return <Tags title={title} onClick={onClick} />;

  if (type === "date") return <Date title={title} onClick={onClick} />;

  return (
    <Button variant="ghost" onClick={onClick}>
      {title}
    </Button>
  );
}

export default Header;

type HeaderVariantProps = Pick<HeaderProps, "title" | "onClick">;

function Status({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <Loader className="w-5 h-5 bg-[#BFC0C2] p-1 text-black rounded-md mr-2" />
      {title}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}

function PhoneNumber({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <Phone className="w-5 h-5 bg-[#FFCACB] p-1 text-black rounded-md mr-2" />
      {title}
      {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
    </Button>
  );
}

function Email({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <Mail className="w-5 h-5 bg-[#FFCACB] p-1 text-black rounded-md mr-2" />
      {title}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}

function ShortText({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <PenLine className="w-5 h-5 bg-[#B5BDF4] p-1 text-black rounded-md mr-2" />
      {title}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}

function Info({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <InfoIcon className="w-5 h-5 bg-[#E6CEF3] p-1 text-black rounded-md mr-2" />
      {title}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}

function Dropdown({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <ChevronDown className="w-5 h-5 bg-[#E6CEF3] p-1 text-black rounded-md mr-2" />
      {title}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}

function MultipleChoices({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <Check className="w-5 h-5 bg-[#E6CEF3] p-1 text-black rounded-md mr-2" />
      {title}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}

function Legal({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <File className="w-5 h-5 bg-[#E6CEF3] p-1 text-black rounded-md mr-2" />
      {title}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}

function Country({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <Globe className="w-5 h-5 bg-[#FFCACB] p-1 text-black rounded-md mr-2" />
      {title}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}

function Section({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <Text className="w-5 h-5 bg-[#BFC0C2] p-1 text-black rounded-md mr-2" />
      {title}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}

function Tags({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick} className="w-32">
      <Tag className="w-5 h-5 bg-[#BFC0C2] p-1 text-black rounded-md mr-2" />
      {title}
      {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
    </Button>
  );
}

function Date({ title, onClick }: HeaderVariantProps) {
  return (
    <Button variant="ghost" onClick={onClick}>
      <Calendar className="w-5 h-5 bg-[#BFC0C2] p-1 text-black rounded-md mr-2" />
      {title}
      <ArrowUpDown className="ml-2 h-4 w-4" />
    </Button>
  );
}
