import { createContext, useState, useContext, useEffect } from "react";
import { SETTING_CONTEXT } from "../Constants/questionTypes.js";
import { updateForm } from "../Redux/slices/formSlice.tsx";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useApi } from "../Api/useApi.ts";
import { message } from "antd";
const FormEditorContext = createContext();
import { convertFormObject } from "../Components/MyEditor/FormObjectConfig.jsx";
import { useFormContext } from "./FormContext.jsx";

// Enum for save states

export const FormEditorProvider = ({ children }) => {
  const SaveStatus = {
    UNSAVED: "UNSAVED",
    SAVING: "SAVING",
    SAVED: "SAVED",
  };
  const dispatch = useDispatch();

  const [form, setForm] = useState({}); //used to save global form details
  const [changed, setChanged] = useState(false);
  const [themeChanged, setThemeChanged] = useState(false);
  const [fields, setFields] = useState([]); //used to store data about fields
  const [welcomeScreen, setWelcomeScreen] = useState({}); //used to store data about welcome screen
  const [selectedField, _setSelectedField] = useState("");
  const [settingsContext, setSettingsContext] = useState(
    SETTING_CONTEXT?.FIELD
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [editorField, setEditorField] = useState(null);
  const [endScreen, setEndScreen] = useState({});
  const [selectedWorkSpace, setSelectedWorkSpace] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [theme, setTheme] = useState({});
  const [name, setName] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const { data, setData } = useFormContext();
  const [isWelcomeIsEnabled, setIsWelcomeIsEnabled] = useState(false);
  const [isEndIsEnabled, setIsEndIsEnabled] = useState(false);
  const [saveStatus, setSaveStatus] = useState(SaveStatus.SAVED);
  const [hasChanges, setHasChanges] = useState(false);
  const [device, setDevice] = useState("desktop");

  useEffect(() => {
    setData({ ...data, fields: fields });
  }, [fields]);

  const setSelectedField = (field) => {
    setEditorField(field);
    _setSelectedField(field);
  };

  useEffect(() => {
    if (form) {
      setFields(form?.fields || []);
      setWelcomeScreen(form?.welcomeScreen || {});
      setEndScreen(form?.endScreen || {});
      setIsPublished(form?.url_id ? true : false);
      setTheme(form?.theme || {});
      setName(form?.name || form?.title || "");
      if (form?.welcomeScreen?.title) {
        setIsWelcomeIsEnabled(true);
        setSelectedField("welcome-screen");
      }
      if (form?.endScreen?.title) {
        setIsEndIsEnabled(true);
      }
    }
  }, [form]);

  const addField = (field) => {
    const updatedFields = [...fields, field];
    setFields(updatedFields);
    dispatch(updateForm({ ...form, ...fields, fields: updatedFields }));
    setChanged(true);
  };

  useEffect(() => {
    if (saveStatus === SaveStatus.UNSAVED) {
      const timeoutId = setTimeout(() => {
        const updatedForm = { ...form, fields, welcomeScreen, endScreen, name };
        dispatch(updateForm(updatedForm));
        saveChanges();
      }, 0);

      // Cleanup the timeout if `changed` becomes false or the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [saveStatus]);

  /* form update endpoint handling  */
  const [messageApi, contextHolder] = message.useMessage();
  const api = useApi();

  const updateMutation = useMutation({
    mutationFn: ({ url_id, form }) => {
      if (!url_id) return console.error("No url_id provided");
      return api.forms.updateForm(url_id, form);
    },
    //api.forms.updateForm(url_id, form),
    retry: (failureCount, error) => {
      // Check if the error is a network error
      if (!error.response) {
        setSaveStatus(SaveStatus.UNSAVED);
        return true; // Retry for network errors
      }
      // Handle specific status codes
      if (error.response.status === 404) {
        setSaveStatus(SaveStatus.UNSAVED);
        return false; // Don't retry for 404
      }
      return failureCount < 3; // Retry up to 3 times
    },
    retryDelay: (attemptIndex) => {
      const delay = Math.min(20000 * 2 ** attemptIndex, 30000); // Max 30 seconds
      // Set the save status to UNSAVED during the delay
      setTimeout(() => {
        setSaveStatus(SaveStatus.UNSAVED);
      }, delay);

      return delay;
    },
    onSuccess: () => {
      setSaveStatus(SaveStatus.SAVED);
      setChanged(false);
    },
    onError: (error) => {
      console.error("Error details:", error);
      if (!error.response) {
        setSaveStatus(SaveStatus.UNSAVED);
      } else {
        // Handle other types of errors as needed
        setSaveStatus(SaveStatus.UNSAVED);
      }
    },
    enabled: !!form?.url_id,
  });

  useEffect(() => {
    // Compare current form state with original to determine if changes exist
    const originalForm = {
      ...form,
      fields: form.fields,
      welcomeScreen: form.welcomeScreen,
      endScreen: form.endScreen,
      name: form.name,
    };

    const currentForm = { fields, welcomeScreen, endScreen, name };

    const hasFormChanged =
      JSON.stringify(originalForm) !== JSON.stringify(currentForm);

    setHasChanges(hasFormChanged);

    // Update save status based on changes
    if (hasFormChanged) {
      setSaveStatus(SaveStatus.UNSAVED);
    }
  }, [fields, welcomeScreen, endScreen, name, form]);

  // Save changes method
  const saveChanges = () => {
    if (!hasChanges) return;
    setSaveStatus(SaveStatus.SAVING);

    const updatedForm = { ...form, fields, welcomeScreen, endScreen, name };
    dispatch(updateForm(updatedForm));
    const newObj = convertFormObject(updatedForm);
    updateMutation.mutate({ url_id: newObj.url_id, form: newObj });
  };

  const goToNext = () => {
    if (selectedField === "welcome-screen") return setSelectedField(fields[0]);

    const index = fields.findIndex((field) => field.id === selectedField?.id);
    if (index === fields.length - 1) {
      setSelectedField("end-screen");
    } else {
      setSelectedField(fields[index + 1]);
    }

    if (selectedField === "end-screen")
      return setSelectedField("welcome-screen");
  };

  return (
    <>
      {contextHolder}
      <FormEditorContext.Provider
        value={{
          form,
          setForm,
          welcomeScreen,
          setWelcomeScreen,
          fields,
          setFields,
          addField,
          selectedField,
          setSelectedField,
          settingsContext,
          setSettingsContext,
          saveChanges,
          editorField,
          setEditorField,
          endScreen,
          setEndScreen,
          selectedWorkSpace,
          setSelectedWorkSpace,
          theme,
          setTheme,
          name,
          setName,
          changed,
          setChanged,
          themeChanged,
          setThemeChanged,
          isPreview,
          setIsPreview,
          isSidebarOpen,
          setIsSidebarOpen,
          isWelcomeIsEnabled,
          setIsWelcomeIsEnabled,
          saveStatus,
          goToNext,
          isEndIsEnabled,
          setIsEndIsEnabled,
          device,
          setDevice,
        }}
      >
        {children}
      </FormEditorContext.Provider>
    </>
  );
};

export const useFormEditorContext = () => {
  const context = useContext(FormEditorContext);
  if (!context) {
    throw new Error("useFormEditorContext must be used within a FormProvider");
  }
  return context;
};
