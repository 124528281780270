import React from "react";
import { Link, useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { useApi } from "../Api/useApi.ts";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../Components/ui/breadcrumb.tsx";
import { Button } from "../Components/ui/button.tsx";
import { Separator } from "../Components/ui/separator.tsx";

const FormResultsLayout = ({ children }) => {
  const api = useApi();
  const { urlId } = useParams();
  const { data: form } = useQuery({
    queryKey: ["form", urlId],
    queryFn: () => api.forms.fetchFormData(urlId),
    staleTime: Infinity,
  });

  // Check whether the current page is analytics or responses
  const isAnalytics = window.location.pathname.includes("analytics");

  return (
    <div className="h-screen px-5 pt-4 text-left">
      <Navbar formId={form?.data?.url_id} formTitle={form?.data?.title} />
      <Separator className="my-2" />
      <div className="text-gray-600">
        <Link to="/" className={`${isAnalytics && "text-black"}`}>
          <Button variant="ghost">Analytics</Button>
        </Link>
        <Link to="/" className={`${!isAnalytics && "text-black"}`}>
          <Button variant="ghost">Responses</Button>
        </Link>
      </div>
      <div className="mt-4">{children}</div>
    </div>
  );
};

export default FormResultsLayout;

function Navbar({ formId, formTitle }) {
  return (
    <nav className="flex flex-row items-center justify-between">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink
              href="/"
              className="flex flex-row items-center font-medium transition duration-150 text-zinc-500 hover:text-blue-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                class="h-3 w-3 mr-1"
              >
                <path d="M10.362 1.093a.75.75 0 0 0-.724 0L2.523 5.018 10 9.143l7.477-4.125-7.115-3.925ZM18 6.443l-7.25 4v8.25l6.862-3.786A.75.75 0 0 0 18 14.25V6.443ZM9.25 18.693v-8.25l-7.25-4v7.807a.75.75 0 0 0 .388.657l6.862 3.786Z"></path>
              </svg>{" "}
              Dashboard
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink
              href={`/form-editor/${formId}`}
              className="flex flex-row items-center font-medium transition duration-150 text-zinc-500 hover:text-blue-600"
            >
              <BreadcrumbPage>{formTitle}</BreadcrumbPage>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Responses</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    </nav>
  );
}
