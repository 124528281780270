import React from "react";
import { Dropdown, Menu } from "antd";
import {
  DeleteOutlined,
  CopyOutlined,
  EyeOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApi } from "../../Api/useApi.ts";
import { useMutation } from "@tanstack/react-query";
import { Badge } from "../ui/badge.tsx";
import mainLogo from "../../assets/images/main-logo.svg";
import { formatDistanceToNow, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";

const FormCard = ({ isFetching, formList }) => {
  const queryClient = useQueryClient();
  const [messageApi, contextHolder] = message.useMessage();
  const api = useApi();
  const deleteMutation = useMutation({
    mutationFn: (id) => api.forms.deleteForm(id),
    onSuccess: () => {
      messageApi.success("Form deleted");
      queryClient.invalidateQueries({ queryKey: ["formList"] });
    },
    onError: (error) => {
      messageApi.error("Could not delete form");
      alert(error);
    },
  });

  const handleMenuClick = (e, form) => {
    const { key } = e;
    if (key === "6") {
      deleteMutation.mutate(form?.url_id);
    }
  };

  console.log("formList", formList);

  return (
    <>
      {contextHolder}
      {isFetching ? (
        <div className="relative h-[12rem] border border-zinc-200 shadow rounded-2xl overflow-hidden animate-pulse">
          <div className="absolute z-20 w-full h-full bg-gray-300 opacity-50"></div>
          <div className="absolute flex items-center justify-center w-full h-full"></div>
          <div className="relative z-20 p-4">
            <div className="w-3/4 h-4 mb-2 bg-gray-300 rounded"></div>
            <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
          </div>
          <div className="absolute bottom-0 left-0 z-20 flex items-center justify-between w-full p-4">
            <div className="flex items-center w-12 gap-2 px-2 bg-gray-300 rounded-lg h-7"></div>
            <div className="bg-gray-300 rounded-lg h-7 w-7"></div>
          </div>
        </div>
      ) : (
        formList?.map((form) => (
          <a
            href={`/form-editor/${form?.url_id}`}
            key={form?.id}
            className="relative h-[12rem] border border-gray-200 shadow-sm rounded-xl overflow-hidden hover:shadow-xl hover:opacity-80 transition duration-500"
          >
            <div className="flex justify-between bg-[#F5F8FF] p-3 h-[93px] ">
              <div className="relative object-contain ">
                <img
                  src={`${
                    form?.settings?.meta?.image
                      ? form?.settings?.meta?.image
                      : `https://s3-alpha-sig.figma.com/img/dcba/b365/1f27e2e6c0523e4f9eb2c889c7f00057?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZSM~Uy8yzMpFx1YDIuup6b4YG2kwsf9P9qGxARPw9kZ5UH5K8zd4Zh~CQEYJajpW5LHFbxgSc04n16QJFLFmZ-YzKddVg04ANk9xvIt5jLLHjEiuaO3APi~I2YwxtuvKQsVz6mZdOeq3oYGieyE~NjMCT2eI08vPm1L8OAOilVUvuSVf9k36sXAUxuzJyid4o6-xeGPUc~r6rA8TfI1pBtq0ATFEFyeKqwLjRVCJ7YoxtL3de4rjFH~CEYWFv4pVDLYtdJX4JoeOVsA0mHUnyxp-8voKeEk8skrxG09jGpw-ITYO~iIBKt12muR4nkSPC4oIMCh1FpBNagfiqu5UEQ__ `
                  }  `}
                  alt="form"
                  className="w-[69px] h-[69px] rounded-[8px]"
                />
                <div className="absolute flex left-[25%]  top-[25%] w-8 h-8 ">
                  <img src={mainLogo} alt="" />
                </div>
              </div>
              <div className="flex flex-col h-full ">
                <div className="flex items-start justify-end gap-1.5 ">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99992 0.833008L10.0599 5.00634L14.6666 5.67967L11.3333 8.92634L12.1199 13.513L7.99992 11.3463L3.87992 13.513L4.66659 8.92634L1.33325 5.67967L5.93992 5.00634L7.99992 0.833008Z"
                      stroke="#44546F"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <Dropdown
                    overlay={
                      <Menu onClick={(e) => handleMenuClick(e, form)}>
                        <Menu.Item key="1" icon={<FolderOpenOutlined />}>
                          <a
                            href={`/form-editor/${form?.url_id}`}
                            className="font-semibold "
                          >
                            Open in Editor
                          </a>
                        </Menu.Item>
                        <Menu.Item key="2" icon={<EyeOutlined />}>
                          <a
                            target="_blank"
                            href={`/forms/${form?.url_id}`}
                            className="font-semibold "
                          >
                            Preview
                          </a>
                        </Menu.Item>
                        <Menu.Item key="3" icon={<CopyOutlined />}>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              navigator.clipboard.writeText(
                                `https://${process.env.REACT_APP_PUBLISHED_URL}/forms/${form?.url_id}`
                              );
                              //message.success("Link copied to clipboard");
                              messageApi.open({
                                type: "success",
                                content: "Link copied to clipboard",
                                className: "custom-class",
                                style: {
                                  marginLeft: "30vh",
                                },
                              });
                            }}
                            className="font-semibold "
                          >
                            Copy Link
                          </a>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="6" icon={<DeleteOutlined />} danger>
                          <button className="flex items-center gap-1 font-semibold text-red-500 hover:text-white">
                            Delete
                          </button>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                    className="hover:cursor-pointer"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <div
                        type="button"
                        aria-haspopup="dialog"
                        aria-expanded="false"
                        aria-controls="radix-:rn:"
                        data-state="closed"
                        className="h-7 w-7 z-[300] relative rounded-lg overflow-hidden flex items-start justify-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          className="relative z-50 w-4 h-4 pointer-events-none"
                        >
                          <path d="M3 10a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM8.5 10a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM15.5 8.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"></path>
                        </svg>
                      </div>
                    </a>
                  </Dropdown>
                </div>
                <div className="flex mt-auto">
                  <div className="flex items-center gap-1">
                    <Badge
                      className=" border-[#2563EB] text-[10px] font-medium text-[#2563EB]"
                      variant={"outline"}
                    >
                      Published
                    </Badge>
                    <Badge
                      className=" border-[#FACE33] text-[10px] font-medium text-[#FACE33]"
                      variant={"outline"}
                    >
                      Draft
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start p-3 py-3 h-[99px] ">
              <div className="flex flex-col gap-1 ">
                <div className="text-sm text-start  font-medium text-[#191B33]">
                  {form?.title}
                </div>
                <div className="text-[10px] flex items-center gap-2  text-[#44546F]">
                  <a
                    href={`/forms/${form?.url_id}/results/responses`}
                    className="flex items-center gap-1 "
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.25 12.7493L3.35833 9.42433C2.83166 8.37129 2.6969 7.16519 2.97818 6.02187C3.25945 4.87856 3.9384 3.87264 4.89353 3.18415C5.84865 2.49565 7.01761 2.1695 8.19121 2.26407C9.36481 2.35865 10.4665 2.86776 11.299 3.70032C12.1316 4.53287 12.6407 5.63452 12.7353 6.80812C12.8298 7.98173 12.5037 9.15068 11.8152 10.1058C11.1267 11.0609 10.1208 11.7399 8.97746 12.0212C7.83414 12.3024 6.62804 12.1677 5.575 11.641L2.25 12.7493Z"
                        stroke="#44546F"
                        stroke-width="0.875"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>10 Responses</span>
                  </a>
                  <div className="flex items-center gap-1 ">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.33333 2.83398H4V12.1673H6.33333V2.83398Z"
                        stroke="#44546F"
                        stroke-width="0.875"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11 2.83398H8.66667V12.1673H11V2.83398Z"
                        stroke="#44546F"
                        stroke-width="0.875"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Responses Paused</span>
                  </div>
                </div>
              </div>
              <div className=" flex items-center mt-auto text-[10px] text-gray-700 font-medium justify-start gap-1">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.49984 3.99935V7.49935L9.83317 8.66602M13.3332 7.49935C13.3332 10.721 10.7215 13.3327 7.49984 13.3327C4.27818 13.3327 1.6665 10.721 1.6665 7.49935C1.6665 4.27769 4.27818 1.66602 7.49984 1.66602C10.7215 1.66602 13.3332 4.27769 13.3332 7.49935Z"
                    stroke="#44546F"
                    stroke-width="0.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <TimestampFormatter timestamp={form?.last_updated_at} />
              </div>
            </div>
          </a>
        ))
      )}
    </>
  );
};

export default FormCard;

const TimestampFormatter = ({ timestamp }) => {
  function getRelativeTimeFromCustomFormat(serverTime) {
    // Get the user's local time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Ensure the time format is ISO 8601 by adding 'Z' if it's missing (assume UTC)
    const formattedServerTime = serverTime + "Z"; // Assumes the time is UTC, so we append 'Z'

    // Parse the server time (which is now in UTC after adding 'Z')
    const parsedServerTime = parseISO(formattedServerTime);

    // Convert the UTC time to the user's local time zone
    const zonedTimeAtServerCreation = toZonedTime(
      parsedServerTime,
      userTimeZone
    );

    // Get the current local time
    const currentLocalTime = new Date(); // Current local time

    // Calculate the relative time between the current time and the server time (converted to local time)
    const relativeTime = formatDistanceToNow(zonedTimeAtServerCreation, {
      addSuffix: true,
    });

    return `Edited ${relativeTime}`;
  }

  return (
    <span className="font-medium text-[10px] text-gray-700">
      {getRelativeTimeFromCustomFormat(timestamp)}
    </span>
  );
};
