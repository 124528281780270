import React, { useState } from "react";

import { format } from "date-fns";

// Components
import { Button } from "../ui/button.tsx";
import { Badge } from "../ui/badge.tsx";
import { Input } from "../ui/input.tsx";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover.tsx";
import { HeaderTypes } from "./Header.tsx";

import { Plus, X } from "lucide-react";

export type CellTypes = HeaderTypes;

type DefaultCellTypes = "tags" | "date";

type CellProps = {
  data: string;
  type: DefaultCellTypes | CellTypes;
  onClick: () => void;
};

function Cell({ data, type, onClick }: CellProps) {
  if (type === "status")
    return <Status status={data as "completed" | "partial"} />;

  if (type === "tags")
    return <Tags tags={[]} onAdd={() => {}} onDelete={() => {}} />;

  if (type === "date") return <DateCell date={data} />;

  return <div className="ml-4">{data}</div>;
}

export default Cell;

type HeaderVariantProps = Pick<CellProps, "data" | "onClick">;

type TagsProps = {
  tags: string[];
  onAdd: (tag: string) => void;
  onDelete: (tag: string) => void;
};

function Tags({ tags: _tags, onAdd, onDelete }: TagsProps) {
  const [tags, setTags] = useState<string[]>(_tags);
  const [tag, setTag] = useState("");
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen} modal={false}>
      <PopoverTrigger
        asChild
        autoFocus={false}
        className="relative flex flex-row flex-wrap gap-1 w-full h-full py-0 my-0 border-none outline-none"
      >
        <div className="max-w-xs">
          {tags.length === 0 && (
            <Button
              variant="link"
              onClick={() => onAdd(tag)}
              className="w-fit h-full text-xs text-gray-500 hover:no-underline"
            >
              <Plus className="w-3 h-3 mr-1" /> Add tag
            </Button>
          )}
          {tags.map((tag) => (
            <Tag tag={tag} />
          ))}
        </div>
      </PopoverTrigger>
      <PopoverContent className="absolute -top-full -left-[150px] flex flex-row flex-wrap gap-1 p-2">
        {tags.map((tag) => (
          <Tag
            tag={tag}
            onDelete={() => {
              setTags(tags.filter((t) => t !== tag));
              onDelete(tag);
            }}
          />
        ))}
        <Input
          autoFocus={true}
          value={tag}
          onChange={(e) => setTag(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && tag.trim()) {
              setTags([...tags, tag]);
              setTag("");
              onAdd(tag);
            }
          }}
          className="outline-none border-none focus:outline-none focus:border-none focus-visible:ring-transparent w-20 h-5 px-1 rounded-none"
        />
      </PopoverContent>
    </Popover>
  );
}

type TagProps = {
  tag: string;
  onDelete?: (tag: string) => void;
};

function Tag({ tag, onDelete }: TagProps) {
  return (
    <Badge variant="outline" className="rounded-md">
      {tag}
      {onDelete && (
        <Button
          variant="ghost"
          size="icon"
          className="w-5 h-5 ml-1"
          onClick={() => onDelete(tag)}
        >
          <X className="w-3 h-3" />
        </Button>
      )}
    </Badge>
  );
}

type DateCellProps = {
  date: string;
};

function DateCell({ date }: DateCellProps) {
  const _date = new Date(date);
  const formattedDate = format(_date, "yyyy-MM-dd");
  const formattedTime = format(_date, "HH:mm");

  return (
    <div>
      <p>{formattedDate}</p>
      <p>{formattedTime}</p>
    </div>
  );
}

type StatusCellProps = {
  status: "completed" | "partial";
};

function Status({ status }: StatusCellProps) {
  return (
    <Badge variant="outline" className="rounded-md">
      {status}
    </Badge>
  );
}
