import { Flex } from "antd";
import { Progress } from "antd";
import Center from "../Basic/Center.jsx";
import { motion } from "framer-motion";
import { useFormContext } from "../../Contexts/FormContext.jsx";
import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";

export const FormHeader = () => {
  //  const [theme, token] = useToken();

  const { percentage, styles, isInitialPage, isEndPage, formTheme } =
    useFormContext();
  const { theme } = useFormEditorContext();
  const isFormEditor = location.pathname.includes("form-editor");
  const constTheme = isFormEditor ? theme : formTheme;

  const strokeColor = constTheme?.button_color;
  // ??
  // {
  //     "0%": tinycolor(styles.primary_color).spin(-5).toString(),
  //     "100%": tinycolor(styles.primary_color).spin(5).toString(),
  //   }
  // styles.primary_color;

  return (
    <FormHeaderFormat
      showPercentage={!isInitialPage && !isEndPage}
      percentage={percentage}
      strokeColor={strokeColor}
    />
  );

  return (
    <div>
      {!isInitialPage && (
        <Center>
          <Progress
            style={{ width: "80%", margin: 0 }}
            percent={percentage}
            type="line"
            showInfo={false}
            strokeColor={strokeColor}
          />
        </Center>
      )}
      <Flex style={{ padding: "1%", justifyContent: "start" }}>
        <motion.div
          initial={{ opacity: 0, translateY: 20 }}
          animate={{ opacity: 1, translateY: 0 }}
        >
          {/* <Image
            src={"https://metana.io/wp-content/uploads/2022/07/Metana-Logo.png"}
            height={30}
          /> */}
        </motion.div>
      </Flex>
      {/* <Typography.Text>{"test"}</Typography.Text> */}
    </div>
  );
};

export const FormHeaderFormat = ({
  showPercentage,
  percentage,
  strokeColor,
}) => {
  return (
    <Flex className="fill-available" vertical>
      {showPercentage && (
        // <Center>
        <Progress
          style={{
            width: "100%",
            margin: 0,
            alignSelf: "center",
            padding: "0 14px",
          }}
          size={"small"}
          percent={percentage}
          type="line"
          showInfo={false}
          strokeColor={strokeColor}
        />
        // </Center>
      )}
      <Flex
        style={{
          padding: "10px",
          paddingLeft: "50px",
          justifyContent: "start",
        }}
      >
        <motion.div
          key={"form-header-logo"}
          initial={{ opacity: 0, translateY: 20 }}
          animate={{ opacity: 1, translateY: 0 }}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {/* <Image
            preview={false}
            src={"https://metana.io/wp-content/uploads/2022/07/Metana-Logo.png"}
            height={40}
            width={"auto"}
            style={{ objectFit: "contain", objectPosition: "left" }}
          /> */}
        </motion.div>
      </Flex>
      {/* <Typography.Text>{"test"}</Typography.Text> */}
    </Flex>
  );
};
