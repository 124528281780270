import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useQuestionContext } from "../../Contexts/QuestionContext.jsx";
import { IMAGES, IQuestion } from "../../Constants/test2.ts";
import { useFormContext } from "../../Contexts/FormContext.jsx";
import { SwipeDetector } from "../Basic/SwipeDetector.jsx";

export const QuestionBackground: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    currentQuestionIndex,
    next,
    formTheme,
  }: {
    currentQuestion: IQuestion;
    currentQuestionIndex: number;
    next: () => void;
    formTheme: any;
  } = useFormContext();

  const delay = 1.5;

  // const onEnterPressed = (event) => {
  //   console.log("key pressed");
  //   if (event.key === "Enter") {
  //     console.log("enter press here! ");
  //   }
  // };

  return (
    <>
      {/* <motion.img
        key={currentQuestionIndex}
        style={{
          backgroundColor: "darkolivegreen",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          objectFit: "cover",
          zIndex: -1,
        }}
        src={currentQuestion?.image || IMAGES[currentQuestionIndex % IMAGES.length]}
        initial={{ translateY: 50 }}
        animate={{ translateY: 0 }}
        transition={{ type: "ease", duration: 0.5, delay: delay }}
      /> */}
      <motion.div
        key={currentQuestionIndex}
        /*     transition={{ type: "ease", duration: 3, delay: delay }}
        initial={{ opacity: 1 }} */
        // animate={{ opacity: 0.8 }}
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: "absolute",
          zIndex: -1,
          // backgroundColor: "rgba(0,0,0,1)",
          backgroundColor: formTheme?.background_color,
        }}
      />
      {/*   <AnimatePresence mode="wait"> */}
      {children}
      {/* </AnimatePresence> */}
    </>
  );
};
