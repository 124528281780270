import React from "react";

import { motion } from "framer-motion";

import { ResizableTextarea } from "../ui/resizable-textarea.tsx";
import FormButton from "./FormButton.tsx";

import useStyles from "../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../Contexts/FormEditorContext.jsx";

import { questionAnimation } from "../../Constants/animations";

import { cn } from "../../lib/utils.js";

function WelcomeScreen() {
  const { getStyles } = useStyles();
  const { welcomeScreen, setWelcomeScreen, theme, setChanged, device } =
    useFormEditorContext();

  const attachment = welcomeScreen?.layout?.attachment;
  const hasAttachment = attachment?.href || attachment?.link;
  const attachmentPlacement =
    (hasAttachment && welcomeScreen?.layout?.placement) || "right";

  const updateWelcomeScreen = (key, value) => {
    setWelcomeScreen({
      ...welcomeScreen,
      [key]: value,
    });
    setChanged(true);
  };

  const getParentStyle = () => {
    if (attachment?.href || attachment?.link) {
      return `h-full flex ${
        attachmentPlacement === "right" ? "flex-row" : "flex-row-reverse"
      } items-center gap-4`;
    }
    return `w-full h-full flex flex-col items-center justify-center`;
  };

  const getDeviceStyle = () => {
    if (device === "mobile") {
      return "p-5";
    }
    return "";
  };

  const parentStyle = getParentStyle();
  const deviceStyle = getDeviceStyle();

  return (
    <div className={cn(parentStyle, deviceStyle)}>
      <motion.div
        initial={{ opacity: 0, translateY: 400 }}
        animate={{ opacity: 1, translateY: 0 }}
        exit={{ opacity: 0, translateY: 40 }}
        transition={{ delay: 0, duration: 0.5 }}
        className="w-full h-full flex flex-col items-center justify-center max-w-[606px]"
      >
        <div className="flex flex-col w-full">
          <ResizableTextarea
            placeholder="Welcome Screen Title"
            value={welcomeScreen?.title || ""}
            onChange={(value) => updateWelcomeScreen("title", value)}
            style={{
              color: theme?.text_color,
              fontSize: getStyles("h1", theme?.text_size, device).fontSize,
              fontWeight: getStyles("h1", theme?.text_size, device).fontWeight,
              fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
            }}
            className="py-2 leading-tight"
          />
          <ResizableTextarea
            placeholder="Add Welcome Screen Description (optional)"
            value={welcomeScreen.description || ""}
            onChange={(value) => updateWelcomeScreen("description", value)}
            style={{
              color: theme?.text_color,
              fontSize: getStyles("h2", theme?.text_size, device).fontSize,
              fontWeight: "400",
              fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
            }}
          />
          <FormButton className="mt-6">Start</FormButton>
        </div>
      </motion.div>

      {attachment?.href && (
        <motion.img
          {...questionAnimation}
          src={attachment?.href || attachment?.link}
          style={{
            // ...attachmentSize,
            objectFit: "contain",
            borderRadius: "18px",
          }}
          className="max-w-2xl"
        />
      )}
    </div>
  );
}

export default WelcomeScreen;
