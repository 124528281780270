import React, { useCallback, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { Input } from "../../ui/input.tsx";
import { Button } from "../../ui/button.tsx";
import { ResizableTextarea } from "../../ui/resizable-textarea.tsx";
import FormButton from "../FormButton.tsx";

import useStyles from "../../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import { QUESTION_TYPES } from "../../../Constants/questionTypes.js";

import { cn } from "../../../lib/utils.js";
import { XIcon } from "lucide-react";

export default function ContactInfo() {
  const { getStyles } = useStyles();
  const { editorField, setEditorField, theme, fields, setFields, device } =
    useFormEditorContext();

  const updateField = (fieldId, key, subkey, value) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === fieldId) {
          if (subkey) {
            return {
              ...field,
              [key]: {
                ...field[key],
                [subkey]: value,
              },
            };
          } else {
            return {
              ...field,
              [key]: value,
            };
          }
        }
        return field;
      });
      return updatedFields;
    });

    setEditorField((prev) => {
      if (subkey) {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [subkey]: value,
          },
        };
      } else {
        return {
          ...prev,
          [key]: value,
        };
      }
    });
  };

  const getSubFieldIndex = () => {
    const currentSubFields = editorField?.properties?.fields || [];
    return currentSubFields.length > 0 ? currentSubFields.length + 1 : 1;
  };

  const handleAddField = () => {
    const newField = {
      id: uuidv4(),
      type: QUESTION_TYPES.SHORT_TEXT,
      title: `Field Title ${getSubFieldIndex()}*`,
      properties: {
        description: "",
        fields: [],
      },
      validations: {
        required: "true",
      },
    };

    const currentSubFields = editorField?.properties?.fields || [];
    const updatedField = {
      ...editorField,
      properties: {
        ...editorField.properties,
        fields: [...currentSubFields, newField],
      },
    };

    setEditorField(updatedField);
    saveChanges(updatedField);
  };

  const saveChanges = useCallback(
    (updatedEditorField) => {
      if (!Array.isArray(fields)) return;

      const newFields = [...fields];
      const index = newFields?.findIndex(
        (f) => f?.id === updatedEditorField.id
      );

      const cleanEditorField = {
        ...updatedEditorField,
        title: updatedEditorField?.title || "",
        properties: {
          ...updatedEditorField.properties,
          description: updatedEditorField.properties?.description || "",
        },
      };

      newFields[index] = Object.fromEntries(
        Object.entries(cleanEditorField).filter(([key, value]) => value != null)
      );
      setFields(newFields);
    },
    [fields, setFields]
  );

  const handleChange = (id: string, value: string) => {
    // Sync input field value changes with form editor context
    setEditorField((prev) => {
      const updatedField = {
        ...prev,
        properties: {
          ...prev.properties,
          fields: prev.properties.fields.map((field) =>
            field.id === id ? { ...field, title: value } : field
          ),
        },
      };

      saveChanges(updatedField);
      return updatedField;
    });
  };

  const handleRemoveField = (id: string) => {
    setEditorField((prev) => {
      const updatedField = {
        ...prev,
        properties: {
          ...prev.properties,
          fields: prev.properties.fields.filter((field) => field.id !== id),
        },
      };

      saveChanges(updatedField);
      return updatedField;
    });
  };

  return (
    <div className="flex flex-col w-full max-w-[606px]">
      <ResizableTextarea
        placeholder="Add question title"
        value={editorField?.title || ""}
        onChange={(value) => updateField(editorField?.id, "title", null, value)}
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h2", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h2", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
      />
      <ResizableTextarea
        placeholder="Add question description (Optional)"
        value={editorField?.properties?.description || ""}
        onChange={(value) =>
          updateField(editorField?.id, "properties", "description", value)
        }
        style={{
          color: theme?.text_color,
          resize: "none",
          fontSize: getStyles("h4", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h4", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
        className="mt-2"
      />

      <div className="mt-6 flex flex-col gap-6">
        {editorField?.properties?.fields?.map((field) => (
          <Field
            key={field?.id}
            id={field?.id}
            title={field?.title}
            onChange={handleChange}
            onRemove={handleRemoveField}
          />
        ))}
      </div>

      <div className="w-full mt-2">
        <button
          style={{
            color: theme?.answer_text_color,
          }}
          onClick={(e) => {
            e.preventDefault();
            handleAddField();
          }}
          className="flex justify-start text-sm font-medium underline"
        >
          Add Fields
        </button>
      </div>
      <FormButton className="mt-6">Next</FormButton>
    </div>
  );
}

type FieldProps = {
  id: string;
  title: string;
  onChange: (id: string, value: string) => void;
  onRemove: (id: string) => void;
};

function Field({ id, title, onChange, onRemove }: FieldProps) {
  const { theme, device } = useFormEditorContext();
  const [text, setText] = useState("");
  const { getStyles } = useStyles();

  return (
    <div id={id} className="flex flex-col w-full max-w-[606px]">
      <div className="flex flex-row items-center justify-between">
        <ResizableTextarea
          placeholder="Field Title*"
          value={title}
          onChange={(value) => onChange(id, value)}
          style={{
            color: theme?.text_color,
            fontSize: getStyles("h3", theme?.text_size, device).fontSize,
            fontWeight: getStyles("h3", theme?.text_size, device).fontWeight,
            fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
          }}
        />
        <Button
          size="icon"
          variant="ghost"
          className="hover:bg-transparent"
          onClick={() => onRemove(id)}
        >
          <XIcon className="w-4 h-4" />
        </Button>
      </div>
      <Input
        value={text}
        onChange={(e) => setText(e.target.value)}
        style={{
          color: theme?.answer_text_color,
          resize: "none",
          fontSize: getStyles("h4", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h4", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
        className={cn(
          `p-0 mt-2 border-x-0 border-t-0 rounded-none border-b border-[#B3B9C4] text-left bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 outline-none overflow-hidden`,
          device === "mobile" ? "h-[36px]" : "h-[40px]"
        )}
      />
    </div>
  );
}
