import React, { useCallback, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { Input } from "../../ui/input.tsx";
import { Button } from "../../ui/button.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog.tsx";
import { ResizableTextarea } from "../../ui/resizable-textarea.tsx";
import FormButton from "../FormButton.tsx";

import useStyles from "../../../Hooks/useStyles.ts";

import { useFormEditorContext } from "../../../Contexts/FormEditorContext.jsx";

import { ChevronDown, XIcon } from "lucide-react";

type Choice = {
  id: string;
  label: string;
  value: string;
};

export default function Dropdown() {
  const { getStyles } = useStyles();
  const { editorField, setEditorField, theme, fields, setFields, device } =
    useFormEditorContext();
  const [openChoicesDialog, setOpenChoicesDialog] = useState(false);

  const updateField = (fieldId, key, subkey, value) => {
    setFields((prevFields) => {
      const updatedFields = prevFields.map((field) => {
        if (field.id === fieldId) {
          if (subkey) {
            return {
              ...field,
              [key]: {
                ...field[key],
                [subkey]: value,
              },
            };
          } else {
            return {
              ...field,
              [key]: value,
            };
          }
        }
        return field;
      });
      return updatedFields;
    });

    setEditorField((prev) => {
      if (subkey) {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            [subkey]: value,
          },
        };
      } else {
        return {
          ...prev,
          [key]: value,
        };
      }
    });
  };

  const handleAddChoices = (choices: Choice[]) => {
    setEditorField((prev) => {
      const updatedField = {
        ...prev,
        properties: {
          ...prev.properties,
          choices: choices,
        },
      };

      saveChanges(updatedField);
      setOpenChoicesDialog(false);
      return updatedField;
    });
  };

  const handleOnAddChoices = (e: any) => {
    e.preventDefault();
    setOpenChoicesDialog(true);
  };

  const saveChanges = useCallback(
    (updatedEditorField) => {
      const newFields = [...fields];
      const index = newFields.findIndex((f) => f?.id === updatedEditorField.id);

      const cleanEditorField = {
        ...updatedEditorField,
        title: updatedEditorField?.title || "",
        properties: {
          ...updatedEditorField.properties,
          description: updatedEditorField.properties?.description || "",
        },
      };

      newFields[index] = Object.fromEntries(
        Object.entries(cleanEditorField).filter(([key, value]) => value != null)
      );

      setFields(newFields);
    },
    [fields, setFields]
  );

  return (
    <div className="flex flex-col w-full max-w-[606px]">
      <ResizableTextarea
        placeholder="Add question title"
        value={editorField?.title || ""}
        onChange={(value) => updateField(editorField?.id, "title", null, value)}
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h2", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h2", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
      />
      <ResizableTextarea
        placeholder="Add question description (Optional)"
        value={editorField?.properties?.description || ""}
        onChange={(value) =>
          updateField(editorField?.id, "properties", "description", value)
        }
        style={{
          color: theme?.text_color,
          fontSize: getStyles("h4", theme?.text_size, device).fontSize,
          fontWeight: getStyles("h4", theme?.text_size, device).fontWeight,
          fontFamily: `${theme?.font_family?.family}, ${theme?.font_family?.category}`,
        }}
        className="mt-2"
      />

      <div className={device === "mobile" ? "mt-4" : "mt-6"}>
        <Select>
          <SelectTrigger
            style={{
              color: theme?.answer_text_color,
            }}
            className="bg-transparent border-[#B3B9C4] border-b border-x-0 border-t-0 rounded-none ring-0 focus:ring-0 ring-offset-0 focus:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:outline-none outline-none text-md px-0"
          >
            <SelectValue
              placeholder="Select an option"
              style={{
                color: theme?.answer_text_color,
              }}
            />
          </SelectTrigger>
          {editorField?.properties?.choices?.length > 0 && (
            <SelectContent>
              {editorField?.properties?.choices?.map((choice: Choice) => (
                <SelectItem key={choice?.id} value={choice?.value}>
                  {choice?.label}
                </SelectItem>
              ))}
            </SelectContent>
          )}
        </Select>
      </div>

      <div className="flex flex-row items-center justify-between w-full mt-2">
        <button
          onClick={handleOnAddChoices}
          className="flex justify-start text-sm font-medium text-gray-600 underline"
        >
          Add Choices
        </button>
        <p className="text-sm text-gray-600">
          {editorField?.properties?.choices?.length ?? 0} Choices Added
        </p>
      </div>
      <FormButton className="mt-6">Next</FormButton>
      <ChoicesDialog
        open={openChoicesDialog}
        choices={editorField?.properties?.choices || []}
        onClose={() => setOpenChoicesDialog(false)}
        onSave={handleAddChoices}
      />
    </div>
  );
}

type ChoicesDialog = {
  open: boolean;
  choices: Choice[];
  onClose: () => void;
  onSave: (choices: Choice[]) => void;
};

const DEFAULT_CHOICE = { id: uuidv4(), label: "", value: "" };

function ChoicesDialog({ open, choices, onClose, onSave }: ChoicesDialog) {
  const [_choices, setChoices] = useState<Choice[]>([
    ...choices,
    DEFAULT_CHOICE,
  ]);

  const handleOnAddChoice = () => {
    setChoices((prev) => [...prev, { id: uuidv4(), label: "", value: "" }]);
  };

  const handleOnRemoveChoice = (id: string) => {
    setChoices((prev) => prev.filter((choice) => choice.id !== id));
  };

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleOnAddChoice();
    }
  };

  const handleOnChangeChoice = (choice: Choice) => {
    setChoices((prev) => prev.map((c) => (c.id === choice.id && choice) || c));
  };

  const handleOnSave = () => {
    // Remove empty choices
    const filteredChoices = _choices.filter((choice) => choice.label !== "");
    onSave(filteredChoices);
  };

  return (
    <Dialog open={open} onOpenChange={() => onClose()}>
      <DialogContent className="p-0 top-[50%]">
        <DialogHeader className="p-3 border-b">
          <DialogTitle className="flex flex-row items-center gap-2">
            <ChevronDown className="w-4 h-4" />
            Add & Edit Choices
          </DialogTitle>
          <DialogDescription>
            Add or edit dropdown menu choices
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-2 px-3">
          {_choices.map((choice) => (
            <ChoiceRow
              key={choice.id}
              id={choice.id}
              choice={choice.label}
              onChange={handleOnChangeChoice}
              onRemove={handleOnRemoveChoice}
              onKeyDown={handleOnKeyDown}
            />
          ))}
          <button
            onClick={(e) => {
              e.preventDefault();
              handleOnAddChoice();
            }}
            className="mt-2 flex justify-start w-fit text-sm font-medium underline text-[#2563EB]"
          >
            Add Choice
          </button>
        </div>
        <DialogFooter className="p-3 border-t">
          <Button
            variant="ghost"
            onClick={(e) => {
              e.preventDefault();
              setChoices([]);
              onClose();
            }}
            className="text-destructive hover:bg-red-50 hover:text-destructive"
          >
            Discard
          </Button>
          <Button
            className="h-[36px] text-sm"
            onClick={(e) => {
              e.preventDefault();
              handleOnSave();
            }}
          >
            Save Choices
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

type ChoiceRowProps = {
  id: string;
  choice: string;
  onChange: (choice: Choice) => void;
  onRemove: (id: string) => void;
  onKeyDown: (e: any) => void;
};

function ChoiceRow({
  id,
  choice,
  onChange,
  onRemove,
  onKeyDown,
}: ChoiceRowProps) {
  return (
    <div id={id} className="flex flex-row items-center gap-1">
      <Input
        placeholder="Enter your choice here"
        className="w-full p-4 focus:border-[#E4E4E7] border-[#E4E4E7] focus-visible:ring-offset-0 focus-visible:outline-none focus-visible:ring-0"
        value={choice}
        autoFocus
        onChange={(e) =>
          onChange({ id, label: e.target.value, value: e.target.value })
        }
        onKeyDown={onKeyDown}
      />
      <Button
        variant="outline"
        size="icon"
        onClick={(e) => {
          e.preventDefault();
          onRemove(id);
        }}
      >
        <XIcon className="w-4 h-4" />
      </Button>
    </div>
  );
}
